<template>
    <div class="menu-select__item">
        <i class="fas fa-search mr-3"></i>
        <cc-input
            @input="searchQuery = $event"
            :value="search"
            type="text"
            placeholder="Search..."
            underline
            ref="input"
            customRef="input-search"
        />
    </div>
</template>

<script>
import * as types from "@/store/types";

import { mapState } from "vuex";

export default {
    props: {
        opened: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            timeout: null,
            searchQuery: this.search || "",
        };
    },
    computed: {
        ...mapState({
            search: (state) => state.filters.search,
        }),
    },
    methods: {
        handleInput() {
            this.$store.dispatch(types.SET_FILTER, {
                search: this.searchQuery,
            });
        },
        handleInputDebounce() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.handleInput();
            }, 300);
        },
    },
    watch: {
        searchQuery: {
            handler() {
                this.handleInputDebounce();
            },
        },
        opened: {
            handler(val) {
                if (!val) return;
                setTimeout(() => {
                    this.$refs.input.$refs["input-search"].focus();
                    this.$refs.input.$refs["input-search"].select();
                }, 100);
            },
        },
    },
};
</script>
