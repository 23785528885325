<template>
    <b-card>
        <template #header>
            <div class="ml-2">
                <p class="text-mini text-gray-500 m-0">{{ $t("in_ton") }}</p>
                <h2>{{ $t("dashboard_ghg_header") }}</h2>
            </div>
        </template>

        <transition mode="out-in" name="slow-fade">
            <D3HorizontalBar
                :data="catData"
                chartKey="catData"
                labelKey="name"
                idKey="id"
                @bar-clicked="$emit('chart-clicked', $event)"
                :customStyle="{
                    key: 'scope',
                    styles: {
                        1: `fill: #5bc8ae;`,
                        2: `fill: #a6b4c6;`,
                        3: `fill: #d1d0ea;`,
                    },
                }"
                :barHeight="40"
                :barPadding="0.5"
                v-if="!loading"
            ></D3HorizontalBar>

            <b-skeleton-img v-else />
        </transition>
    </b-card>
</template>

<script>
import D3HorizontalBar from "@/components/charts/D3HorizontalBar.vue";
import categoryApi from "@/api/emission_categories";

export default {
    components: {
        D3HorizontalBar,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            catData: [],
            categories: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.loading = true;

            await this.getCategories();
            this.generatePerCatData();

            this.loading = false;
        },
        async getCategories() {
            try {
                const result = await categoryApi.listAllCategories();
                this.categories = result.data;
            } catch (error) {
                console.log(error);
            }
        },
        generatePerCatData() {
            this.catData = this.data.totalsByCategory.map((x) => {
                const cat = this.categories.find((y) => y.id === x.id);
                return {
                    ...x,
                    name: this.$t(x.name),
                    emissions: x.total,
                    unit: "tCO₂e",
                    click: "category",
                    scope: cat.scope,
                };
            });
        },
    },
    watch: {
        data: {
            handler() {
                this.init();
            },
            deep: true,
        },
    },
};
</script>
