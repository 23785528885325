<template>
    <td class="text-right">
        <b-badge
            v-if="metricValue.attachments"
            class="badge--noclick custom-metric-value__attachments"
        >
            <i class="fa-light fa-file pr-1"></i>

            {{ displayAttachmentText(metricValue.attachments.length) }}</b-badge
        >
    </td>
</template>

<script>
export default {
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
    },
    methods: {
        displayAttachmentText(count) {
            return count === 1
                ? `${count} ${this.$t("file.one")}`
                : `${count} ${this.$t("file.other")}`;
        },
    },
};
</script>
