<template>
    <div class="cc-accordion">
        <div
            class="cc-accordion__header shadow medium d-flex justify-content-between align-items-center"
            @click.prevent="toggleAccordionVisibility"
        >
            <div class="d-flex align-items-center">
                <div
                    class="cc-accordion__header-icon d-flex align-items-center"
                >
                    <i
                        class="fas fa-chevron-right cc-accordion__header-icon-animation"
                        :class="
                            showAccordionItems
                                ? 'cc-accordion__header-icon-animation--active'
                                : null
                        "
                    ></i>
                </div>
                <span
                    class="cc-accordion__header-text d-flex flex-column align-items-start justify-content-center"
                >
                    <h3>{{ customMetric.name }}</h3>
                    <b-form-text
                        :id="customMetric.id + 'metric-description'"
                        v-if="customMetric.description"
                        class="font-weight-light cc-table__column--ellipsis"
                    >
                        {{ customMetric.description }}
                    </b-form-text>
                    <b-popover
                        v-if="customMetric.description?.length > 40"
                        :target="customMetric.id + 'metric-description'"
                        placement="right"
                        triggers="hover"
                    >
                        <span class="bold">{{ customMetric.description }}</span>
                    </b-popover>
                </span>
                <b-badge
                    class="d-flex ml-5 align-items-center"
                    variant="secondary"
                    >{{ customMetric.unit }}</b-badge
                >
                <b-badge
                    class="d-flex ml-2 align-items-center"
                    variant="secondary"
                    >{{ domain }}</b-badge
                >
            </div>
            <!-- Accordion action buttons -->
            <span v-if="period.locked !== 1">
                <b-button
                    v-if="$can('update', 'metric')"
                    :id="'edit_button' + customMetric.id"
                    size="sm"
                    variant="outline-primary"
                    @click.stop="$emit('select-metric', customMetric)"
                >
                    <i class="fas fa-pencil"></i>
                </b-button>
                <b-tooltip
                    :target="'edit_button' + customMetric.id"
                    triggers="hover"
                >
                    {{ $t("edit") }}
                </b-tooltip>
                <b-button
                    v-if="$can('delete', 'metric')"
                    :id="'delete_button' + customMetric.id"
                    size="sm"
                    variant="outline-danger"
                    @click.stop="$emit('delete-metric', customMetric)"
                >
                    <i class="fas fa-trash"></i>
                </b-button>
                <b-tooltip
                    :target="'delete_button' + customMetric.id"
                    triggers="hover"
                >
                    {{ $t("delete") }}
                </b-tooltip>
            </span>
        </div>
        <!-- Accordion table  -->
        <transition name="slide-down">
            <div class="cc-accordion__list" v-if="showAccordionItems">
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
import DomainMixin from "@/mixins/custom_metrics/DomainMixin";
import { mapState } from "vuex";

export default {
    name: "MetricAccordion",
    mixins: [DomainMixin],
    components: {},
    props: {
        customMetric: {
            type: Object,
            required: true,
            description: "The custom metric data.",
        },
        collapseAll: {
            type: Boolean,
            required: true,
            default: false,
        },
        expandAll: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
    data() {
        return {
            showAccordionItems: false,
            domain: null,
        };
    },
    computed: {
        ...mapState({ periodId: (state) => state.filters.period }),
        period() {
            return this.$store.getters.getCurrentPeriod();
        },
    },
    methods: {
        toggleAccordionVisibility() {
            this.showAccordionItems = !this.showAccordionItems;
        },
    },

    watch: {
        customMetric: {
            handler(newVal) {
                if (newVal) {
                    this.selectedDomain(newVal);
                }
            },
            immediate: true,
        },
        collapseAll: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;

                if (newVal === true) this.showAccordionItems = false;
            },
        },
        expandAll: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;

                if (newVal === true) this.showAccordionItems = true;
            },
        },
        showAccordionItems: {
            handler(newVal) {
                if (newVal) {
                    this.$emit("accordion-opened", this.customMetric.id);
                } else {
                    this.$emit("accordion-closed", this.customMetric.id);
                }
            },
        },
    },
};
</script>
