<template>
    <div class="d-flex flex-column mr-4 pr-1">
        <label for="fileUpload">{{ $t("attachments") }}</label>
        <p class="mb-2 text-gray-500 text-tiny" v-if="!customMetricValueId">
            {{ $t("attachment_enter_value") }}
        </p>
        <div v-if="loading" class="pb-3">
            <b-spinner label="Spinning" small class="mt-1"></b-spinner>
            {{ $t("uploading") }}....
        </div>
        <transition-group
            name="list"
            tag="span"
            v-if="uploadedAttachments.length > 0"
        >
            <div
                class="custom-metric__attachment"
                v-for="attachment in uploadedAttachments"
                :key="attachment.id"
            >
                <span>
                    <span class="custom-metric__attachment-text">
                        {{ attachment.filename }}
                    </span>
                    <p class="custom-metric__attachment-text pb-0 m-0">
                        {{ sizeFormatting(attachment.filesize) }}
                    </p>
                </span>
                <div class="d-flex align-items-center">
                    <i
                        class="fa-solid fa-download custom-metric__attachment-icon mr-3"
                        @click="downloadAttachment(attachment)"
                    ></i>
                    <i
                        class="fa-solid fa-trash custom-metric__attachment-icon"
                        @click="removeAttachment(attachment)"
                    ></i>
                </div>
            </div>
        </transition-group>

        <!-- Displayed file input -->
        <input
            type="file"
            ref="file-upload"
            accept=".xls,.xlsx, .pdf, .csv"
            style="display: none"
            @change="handleFileUpload"
        />
        <!-- Visible button to trigger file input click -->
        <b-button
            class="custom-metric__file-upload-button"
            @click="triggerFileUpload"
            :disabled="customMetricValueId === null"
        >
            <i class="fa-light fa-plus mr-2 text-dark"></i>
            {{ $t("upload") }}
        </b-button>
    </div>
</template>
<script>
import customMetricsApi from "@/api/custom_metrics";
import swal from "sweetalert2";
export default {
    name: "MetricAttachments",
    props: {
        customMetricValueId: {
            type: Number | null,
            required: true,
            default: null,
        },
    },
    data() {
        return {
            uploadedAttachments: [],
            loading: false,
        };
    },

    methods: {
        init() {
            this.listFiles();
            this.loading = false;
        },
        // List all files
        async listFiles() {
            if (this.customMetricValueId === null) return;
            try {
                const response = await customMetricsApi.listAttachments(
                    "metric_value",
                    this.customMetricValueId
                );

                // Set uploadedAttachments only if there are attachments
                this.uploadedAttachments = response.data.data || [];
            } catch (error) {
                console.log(error);
            }
        },

        // Trigger file input click
        triggerFileUpload() {
            this.$refs["file-upload"].click();
        },
        // Upload file
        async handleFileUpload() {
            this.loading = true;
            // Create a FormData object with the file
            const file = this.$refs["file-upload"].files[0];
            const formData = new FormData();
            formData.append("file", file);

            try {
                // Upload the logo, id gets returned, add that id to the company data
                const response = await customMetricsApi.uploadAttachment(
                    "metric_value",
                    this.customMetricValueId,
                    formData
                );
                if (response.data.error) {
                    console.log(
                        `Error ${response.data.status_code}: ${response.data.message}`
                    );
                    return;
                }

                // Reset the file input after upload
                this.$refs["file-upload"].value = null;
                this.$emit("file-uploaded", true);
            } catch (error) {
                console.log(error);
            } finally {
                this.init();
            }
        },

        sizeFormatting(bytes) {
            if (bytes === 0) return "0 Bytes";

            const k = 1024;
            const sizes = ["Bytes", "KB", "MB"];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return (
                parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
            );
        },

        async removeAttachment(attachment) {
            const result = await swal.fire({
                title: "Remove attachment ?",
                text: "You're about to remove an attachment from this metric value; this action cannot be undone. Are you sure you want to remove it?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, remove",
                cancelButtonText: "Cancel",
                confirmButtonColor: "#d33",
            });

            if (!result.value) return;

            try {
                const response = await customMetricsApi.deleteAttachment(
                    "metric_value",
                    this.customMetricValueId,
                    attachment.id
                );
                if (response.data.error) {
                    console.log(
                        `Error ${response.data.status_code}: ${response.data.message}`
                    );
                    return;
                }
                this.uploadedAttachments = this.uploadedAttachments.filter(
                    (file) => file.id !== attachment.id
                );
            } catch (error) {
                console.log(error);
            } finally {
                this.init();
            }
        },

        async downloadAttachment(attachment) {
            try {
                const response = await customMetricsApi.downloadAttachment(
                    "metric_value",
                    this.customMetricValueId,
                    attachment.id
                );
                // Reconstruct the file
                const file = window.URL.createObjectURL(response.data);

                // Create an invisible link to download
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = file;
                a.download = attachment.filename;
                document.body.appendChild(a);
                a.click();

                // Cleanup
                window.URL.revokeObjectURL(response.data);
            } catch (error) {
                console.log(error);
            }
        },
    },
    watch: {
        customMetricValueId: {
            handler(newVal) {
                if (newVal === null) this.uploadedAttachments = [];
                this.init();
                return newVal;
            },
            immediate: true,
            deep: true,
        },
    },
};
</script>
