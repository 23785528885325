import { getInstance } from "../../auth/index";
import * as types from "@/store/types";
import store from "@/store";
import userApi from "@/api/user";
import companyApi from "@/api/company";
import { checkRights } from "@/util/permissions.js";

const loadTokenIntoStore = async () => {
    const instance = getInstance();
    const authToken = await instance.getTokenSilently();
    sessionStorage.setItem("auth_token", authToken);
};

const getUserAndCompany = async () => {
    try {
        // Get all available companies
        const companies = await userApi.getAvailableCompanies();

        // If no companies are available, throw an error
        if (companies.data.length === 0) throw "";
        // Otherwise save these companies to the store
        store.dispatch(types.SET_COMPANIES, companies.data);

        // Load in the current company data
        const company = await companyApi.getDetails();
        await store.dispatch(types.SET_COMPANY, company.data);

        // Load in the user data last, because there are watchers waiting for this dispatch
        const user = await userApi.getUser();
        await store.dispatch(types.SET_USER, user.data);

        return true;
    } catch (error) {
        await store.dispatch(types.THROW_ERROR, {
            title: "User cannot be found",
            error: `We are not able to find a company associated with this user. Please check if your trial period has expired.<br><br>
                For more support, please contact us via email.<br>
                <a href="mailto:info@futureproofed.com">info@futureproofed.com</a>`,
            redirectOnOk: "login",
            logout: true,
        });
        return false;
    }
};

export const authGuard = (to, from, next) => {
    const authService = getInstance();

    const verify = async () => {
        try {
            // If the user is authenticated, grab a token, the user and continue with the route
            if (authService.isAuthenticated) {
                await loadTokenIntoStore();

                const success = await getUserAndCompany();
                if (!success) return next("/login");

                await store.dispatch(types.POPULATE);

                // Check permissions for this route
                // const allow = checkRole(user, to.path);
                // if (!allow) return next("/no-access");
                if (
                    to.path === "/dashboard" &&
                    !checkRights("read", "dashboard")
                ) {
                    next("/data-input");
                    return;
                }

                return next();
            }

            // Otherwise, log in
            next("/login");
        } catch (error) {
            console.log(error);
            // If the user is authenticated but we still receive an error message from backend, they're
            // probably not linked to a company. If the user is not authenticated, they just need to login, so we don't
            // push an error and manually log them out (because this triggers an infinite loop).
            if (authService.isAuthenticated) {
                await store.dispatch(types.THROW_ERROR, {
                    errorData: error?.response?.data,
                });
                setTimeout(() => {
                    authService.logout();
                }, 3000);
            }
        }
    };

    // If loading has already finished, check our auth state using `verify()`
    if (!authService.loading) return verify();

    // Watch for the loading property to change before we check isAuthenticated
    authService.$watch("loading", (loading) => {
        if (loading === false) return verify();
    });
};
