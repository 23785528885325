<template>
    <div>
        <b-row>
            <b-col cols="12" md="8" class="p-2">
                <b-card>
                    <template #header>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emission_evolution") }}</h2>
                        </div>
                    </template>

                    <transition mode="out-in" name="slow-fade">
                        <D3Bar
                            :data="siteEvolution"
                            chartKey="siteEvolution"
                            labelKey="period_name"
                            idKey="period_id"
                            :highlighted="filters.period"
                            @bar-clicked="handleSiteChartClicked"
                            v-if="!loading"
                        ></D3Bar>
                        <b-skeleton-img v-else />
                    </transition>
                </b-card>
            </b-col>
            <b-col cols="12" md="4" class="p-2">
                <stats-card
                    type=""
                    icon="fas fa-smoke stats-card__icon"
                    iconClasses="text-white"
                    class="bg-dashboard-2 text-white px-4 py-2"
                >
                    <h3 class="card-title text-white mb-1 light">
                        {{ $t("total_emissions") }}
                    </h3>
                    <div>
                        <span class="h1 font-weight-bold mb-0 text-white">
                            {{ total }}
                        </span>
                        <span
                            class="text-nowrap text-tiny ml-3 text-white light"
                            >{{ $t("ton_co2e") }}
                        </span>
                    </div>

                    <template slot="footer">
                        <div class="d-flex justify-content-between">
                            <span
                                class="text-nowrap color-success light"
                            ></span>
                            <span class="text-nowrap">
                                <b-button
                                    size="sm"
                                    variant="outline-light"
                                    @click="$router.push('/data-input')"
                                >
                                    {{ $t("view_data") }}
                                    <i class="fas fa-chevron-right ml-2"></i>
                                </b-button>
                            </span>
                        </div>
                    </template>
                </stats-card>
                <b-card class="px-4 py-2">
                    <b-card-text>
                        <p class="text-small text-gray-500">
                            <i class="fas fa-users mr-2"></i
                            >{{ selectedSite.fte }}
                        </p>
                        <p class="text-small text-gray-500">
                            <i class="fas fa-map-marker-alt mr-2"></i
                            >{{ selectedSite.city }},
                            {{ getFullCountry(selectedSite.country_code) }}
                        </p>
                        <p class="text-small text-gray-500">
                            <i class="fas fa-user-cog mr-2"></i
                            >{{ selectedSite.responsible_user_id }}
                        </p>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import D3Bar from "@/components/charts/D3Bar";
import InsightsMixin from "../mixins/InsightsMixin.vue";

import CountryMixin from "@/mixins/countries/CountryMixin";

export default {
    mixins: [InsightsMixin, CountryMixin],
    components: {
        D3Bar,
    },
    props: {
        selectedSite: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            siteEvolution: [],
            loading: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getTotal();
            this.loading = true;
            const siteEvolution = this.getSiteEvolution();
            await Promise.all([siteEvolution]);
            this.loading = false;
        },
        handleSiteChartClicked(data) {
            this.handleChartClicked(data);
            this.$router.push({ path: "/data-input", query: { expandAll: 1 } });
        },
    },
};
</script>
