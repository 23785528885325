<template>
    <div>
        <!--Metric Value Header -->
        <div class="d-flex justify-content-end align-items-center py-3">
            <b-button
                v-if="$can('create', 'metric')"
                variant="primary"
                class="ml-3"
                @click="createCustomMetric"
            >
                + {{ $t("new_metric") }}
            </b-button>
        </div>

        <!-- Empty Custom Metrics View -->
        <empty-metric-view v-if="!customMetrics" />

        <!-- Custom Metrics List -->
        <div v-else>
            <!-- Custom Metric Header -->
            <metric-header
                @expand-all="toggleExpandAll"
                @collapse-all="toggleCollapseAll"
                :expandable="expandable"
            />

            <div v-for="customMetric in customMetrics" :key="customMetric.id">
                <metric-accordion
                    :ref="`metricAccordion-${customMetric.id}`"
                    :customMetric="customMetric"
                    :expandAll="expandAll"
                    :collapseAll="collapseAll"
                    @select-metric="selectMetric"
                    @delete-metric="openDeleteMetricModal"
                >
                    <metric-accordion-table
                        :ref="`metricAccordionTable-${customMetric.id}`"
                        :customMetric="customMetric"
                        :navigate-metric-value="navigateMetricValueItem"
                        @reset-navigation="navigateMetricValueItem = 0"
                        @select-metric-value="selectedCustomMetricAction"
                        :key="updateKey"
                    />
                </metric-accordion>
            </div>
        </div>

        <!-- Custom Metrics Sidebar -->
        <metric-sidebar
            ref="metric_sidebar_component"
            :selectedCustomMetric="selectedCustomMetric"
            :selectedCustomMetricValue="selectedCustomMetricValue"
            @navigate-metric-values="navigateMetricValues"
            @refresh="handleRefresh"
        >
            <template v-slot:attachments>
                <MetricsAttachments
                    name="attachments"
                    :customMetricValueId="selectedCustomMetricValue.id"
                />
            </template>
        </metric-sidebar>
    </div>
</template>
<script>
import customMetricsApi from "@/api/custom_metrics";
import MetricAccordion from "@/components/custom_metrics/metricsAccordion/MetricAccordion.vue";
import MetricHeader from "@/components/custom_metrics/MetricHeader.vue";
import MetricSidebar from "@/components/custom_metrics/metricsSidebar/MetricSidebar.vue";
import swal from "sweetalert2";
import { mapState } from "vuex";
import EmptyMetricView from "./EmptyMetricView.vue";
import MetricAccordionTable from "./metricsAccordion/MetricAccordionTable.vue";
import MetricsAttachments from "./metricsSidebar/data-input/MetricAttachments.vue";

export default {
    name: "MetricList",
    components: {
        MetricSidebar,
        MetricHeader,
        MetricAccordion,
        EmptyMetricView,
        MetricAccordionTable,
        MetricsAttachments,
    },

    data() {
        return {
            customMetrics: [],
            selectedCustomMetric: null,
            selectedCustomMetricValue: null,
            updateMetricValueTable: false,
            expandAll: false,
            collapseAll: false,
            loading: false,
            expandable: true,
            navigateMetricValueItem: 0,
            updateKey: 0,
        };
    },

    mounted() {
        this.init();
    },

    computed: {
        ...mapState({
            businessUnits: (state) => state.data.businessUnits,
            sites: (state) => state.data.sites,
            periodId: (state) => state.filters.period,
        }),

        selectSites() {
            const sites = this.sites.map((x) => {
                return { value: x.id, text: x.name };
            });
            return sites;
        },

        selectBu() {
            const bu = this.businessUnits.map((x) => {
                return { value: x.id, text: x.name };
            });
            return bu;
        },
    },

    methods: {
        init() {
            this.getAllCustomMetrics();
        },

        async getAllCustomMetrics() {
            try {
                this.loading = true;
                const response = await customMetricsApi.list();
                this.customMetrics = response.data.data;
                this.updateKey++;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        // Custom metric actions //
        createCustomMetric() {
            this.selectedCustomMetric = null;
            this.selectedCustomMetricValue = null;
            this.$refs.metric_sidebar_component.open();
        },

        selectMetric(metric) {
            this.selectedCustomMetric = { ...metric };
            this.selectedCustomMetricValue = null;
            this.$refs.metric_sidebar_component.open();
        },

        async openDeleteMetricModal(metric) {
            const entryDelete = await swal.fire({
                title: `${this.$t("delete")} ${metric.name} ?`,
                html: `<p class="my-4"> ${this.$t(
                    "delete_metric_message"
                )} </p>`,
                icon: "warning",
                width: 600,
                showCancelButton: true,
                confirmButtonText: this.$t("delete_confirm"),
                cancelButtonText: this.$t("delete_keep"),
                confirmButtonColor: "#d33",
                showCloseButton: true,
            });

            if (entryDelete.isConfirmed) {
                this.deleteMetric(metric.id);
            }
        },

        async deleteMetric(id) {
            try {
                await customMetricsApi.delete(id);
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        selectedCustomMetricAction(value) {
            const { metricValue, customMetric } = value;
            metricValue.period_id === null
                ? (metricValue.period_id = this.periodId)
                : metricValue;
            this.selectedCustomMetricValue = { ...metricValue };
            this.selectedCustomMetric = { ...customMetric };

            this.$refs.metric_sidebar_component.open();
        },

        handleRefresh() {
            this.init();
        },

        // Controls accordion expand/collapse
        toggleExpandAll() {
            this.expandAll = true;
            this.expandable = false;
            this.$nextTick(() => (this.expandAll = false));
        },
        toggleCollapseAll() {
            this.collapseAll = true;
            this.expandable = true;
            this.$nextTick(() => (this.collapseAll = false));
        },

        // Controls for navigation has been hidden for now
        navigateMetricValues(navigationDetails) {
            const { metric_id } = navigationDetails;
            this.$refs[`metricAccordionTable-${metric_id}`][0].navigateValue(
                navigationDetails
            );
        },
    },
    watch: {
        periodId() {
            this.init();
        },
    },
};
</script>
