var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"cc-content cc-content-card"},[_c('b-row',[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t("Team")))])])],1),_c('div',{staticClass:"mt-6"},[_c('b-table',{attrs:{"striped":"","hover":"","fields":_vm.teamFields,"items":_vm.team,"responsive":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(`${data.item.first_name} ${data.item.last_name}`)+" ")]}},{key:"cell(site_access)",fn:function(data){return [_c('div',[(
                                !data.item.access_all_sites &&
                                _vm.createSiteAccessArray(
                                    data.item.constraints?.site_ids
                                ).length > 0
                            )?_c('div',[_c('b-badge',{staticClass:"badge--blue badge--noclick",class:{
                                    'badge--red': _vm.createSiteAccessArray(
                                        data.item.constraints?.site_ids
                                    )[0].deleted,
                                    'badge--blue': !_vm.createSiteAccessArray(
                                        data.item.constraints?.site_ids
                                    )[0].deleted,
                                }},[_vm._v(" "+_vm._s(_vm.createSiteAccessArray( data.item.constraints?.site_ids )[0].name)+" ")]),(
                                    _vm.createSiteAccessArray(
                                        data.item.constraints?.site_ids
                                    ).length > 1
                                )?_c('p',{staticClass:"text-gray-500 light text-small ml-1 mb-0"},[_vm._v(" "+_vm._s(_vm.createSiteAccessArray( data.item.constraints?.site_ids ).length - 1)+" ")]):_vm._e()],1):_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(" "+_vm._s(_vm.$t("all_sites"))+" ")])],1)]}},{key:"cell(business_access)",fn:function(data){return [_c('div',[(
                                !data.item.access_all_business_units &&
                                _vm.createBusinessAccessArray(
                                    data.item.constraints?.business_unit_ids
                                ).length > 0
                            )?_c('div',[_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(" "+_vm._s(_vm.createBusinessAccessArray( data.item.constraints ?.business_unit_ids )[0]?.name || _vm.$t("al_unknown"))+" ")]),(
                                    _vm.createBusinessAccessArray(
                                        data.item.constraints
                                            ?.business_unit_ids
                                    ).length > 1
                                )?_c('p',{staticClass:"text-gray-500 light text-small ml-1 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("and"))+" "+_vm._s(_vm.createBusinessAccessArray( data.item.constraints ?.business_unit_ids ).length - 1)+" "+_vm._s(_vm.$t("more"))+" ")]):_vm._e()],1):_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(_vm._s(_vm.$t("all_business_units")))])],1)]}},{key:"cell(tag_access)",fn:function(data){return [_c('div',[(
                                !data.item.access_all_tags &&
                                _vm.createTagsAccessArray(
                                    data.item.constraints?.tag_ids
                                ).length > 0
                            )?_c('div',[_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(" "+_vm._s(_vm.createTagsAccessArray( data.item.constraints?.tag_ids )[0].title)+" ")]),(
                                    _vm.createTagsAccessArray(
                                        data.item.constraints?.tag_ids
                                    ).length > 1
                                )?_c('p',{staticClass:"text-gray-500 light text-small ml-1 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("and"))+" "+_vm._s(_vm.createTagsAccessArray( data.item.constraints?.tag_ids ).length - 1)+" "+_vm._s(_vm.$t("more"))+" ")]):_vm._e()],1):_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(_vm._s(_vm.$t("all_tags")))])],1)]}},(_vm.$can('update', 'company_user'))?{key:"cell(role)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(_vm.$t(`form_${data.item.role.toLowerCase()}`))+" ")])]}}:null,{key:"cell(actions)",fn:function(data){return [(_vm.$can('update', 'company_user'))?_c('b-button',{staticClass:"ml-2",attrs:{"id":'edit_button' + data.item.id,"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.handleEditUser(data.item)}}},[_c('i',{staticClass:"fas fa-pencil"})]):_vm._e(),_c('b-tooltip',{attrs:{"target":'edit_button' + data.item.id,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),(
                            _vm.$can('delete', 'company_user') &&
                            data.item.role !== 'admin'
                        )?_c('b-button',{staticClass:"ml-2",attrs:{"id":'delete_button' + data.item.id,"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.handleRemove(data.item)}}},[_c('i',{staticClass:"fas fa-trash"})]):_vm._e(),_c('b-tooltip',{attrs:{"target":'delete_button' + data.item.id,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])]}}],null,true)})],1)],1),_c('Invites'),_c('team-edit-modal',{attrs:{"showModal":_vm.showUserForm,"editUser":_vm.editUser},on:{"close":function($event){_vm.showUserForm = false},"saved":_vm.handleSaved}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }