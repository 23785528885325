<template>
    <div class="cc-input__wrapper">
        <input
            :ref="customRef"
            class="cc-input"
            :class="[
                size === 'lg' ? 'cc-input--large' : '',
                error ? 'cc-input--error' : '',
                underline ? 'cc-input--underline' : '',
                warning ? 'cc-input--warning' : '',
                customClass,
            ]"
            v-model="inputValue"
            :type="type"
            :min="min"
            :max="max"
            :placeholder="placeholder"
            :required="required"
            :disabled="disabled"
            :readonly="readonly"
            @blur="$emit('blur')"
            @click="$emit('click')"
            @focus="$emit('focus')"
            @keydown="$emit('keydown', $event)"
            :id="customId"
        />
        <i
            :class="[
                appendIcon,
                appendIconClickable ? 'cc-input__append--clickable' : '',
            ]"
            class="text-gray-500 cc-input__append"
            @click.stop="$emit('append-icon-clicked')"
        ></i>
        <div class="cc-input__append cc-input__append--text" v-if="appendText">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: "text",
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        min: {
            type: String,
            required: false,
        },
        max: {
            type: String,
            required: false,
        },
        appendText: {
            type: Boolean,
            required: false,
            default: false,
        },
        appendIcon: {
            type: String,
            required: false,
            default: null,
        },
        appendIconClickable: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: "",
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        underline: {
            type: Boolean,
            required: false,
            default: false,
        },
        warning: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        customId: {
            type: String,
            required: false,
            default: "",
        },
        customRef: {
            type: String,
            required: false,
            default: "",
        },
        customClass: {
            type: String,
            required: false,
            default: "",
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        focusInput() {
            this.$refs["input"].focus();
        },
    },
};
</script>
