<template>
    <div class="position-relative h-100">
        <!-- SORT BUTTON -->
        <div
            class="menu-button"
            :class="opened ? 'menu-button--active' : ''"
            @click="toggleMenu"
        >
            <i class="fa-solid fa-arrow-down-arrow-up mr-2"></i>
            {{ $t("sort") }}
        </div>

        <!-- SORT MENU -->
        <div
            class="menu-select__wrapper"
            :class="opened ? 'menu-select__wrapper--visible' : ''"
            :style="shiftLeft ? 'right: 0px;' : ''"
        >
            <!-- MAIN SORT ITEMS -->
            <div class="d-flex flex-column">
                <div
                    class="menu-items__wrapper"
                    id="sort-select"
                    :class="opened ? 'menu-items__wrapper--visible' : ''"
                >
                    <!-- SORT OPTIONS -->
                    <div
                        v-for="option in sortOptions"
                        :key="option.value"
                        class="menu-select__item"
                        @click="selectSort(option)"
                        :class="
                            sort === option ? 'menu-select__item--selected' : ''
                        "
                    >
                        <span>
                            <i
                                class="fas mr-3"
                                :class="generateSortIcon(option.value)"
                            ></i
                            >{{ option.text }}
                            <i
                                class="fa-solid fa-circle menu-select__indicator"
                                v-if="sort === option.value"
                            ></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as types from "@/store/types";
import { mapState } from "vuex";

export default {
    name: "CCSort",
    props: {
        sortOptions: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            opened: false,
            shiftLeft: false,
        };
    },
    computed: {
        ...mapState({
            sort: (state) => state.sort,
        }),
    },
    methods: {
        toggleMenu() {
            this.opened = !this.opened;
            if (this.opened === false) this.selected = "";
            if (this.opened === true) {
                this.$emit("opened", "sort");
                this.checkPosition();
            }
        },
        closeMenu() {
            this.opened = false;
            this.selected = "";
            this.shiftLeft = 0;
        },
        selectSort(sort) {
            this.$store.dispatch(types.SET_SORT, sort.value);
            this.toggleMenu();
        },
        generateSortIcon(sort) {
            switch (sort) {
                case "site":
                    return "fa-map-marker-alt";
                case "bu":
                    return "fa-building";
                case "nameAZ":
                    return "fa-arrow-down-a-z";
                case "nameZA":
                    return "fa-arrow-up-a-z";
                case "descriptionAZ":
                    return "fa-arrow-down-a-z";
                case "descriptionZA":
                    return "fa-arrow-up-a-z";
                case "value":
                    return "fa-hashtag";
                case "type":
                    return "fa-arrow-down-a-z";
                case "date":
                    return "fa-calendar";
                case "emissions09":
                    return "fa-arrow-down-9-1";
                case "emissions90":
                    return "fa-arrow-down-1-9";
            }
        },
        checkPosition() {
            const options = {
                root: document.querySelector(".main-content"),
                rootMargin: "0px",
                threshold: 1.0,
            };
            const target = document.getElementById("sort-select");
            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) this.shiftLeft = true;
                });
            }, options);

            observer.observe(target);
        },
    },
};
</script>
