<template>
    <td
        class="cc-table__column--ellipsis"
        :class="
            sort === 'descriptionAZ' || sort === 'descriptionZA'
                ? 'cc-table__column--highlight'
                : ''
        "
    >
        <span :id="metricValue.id + 'metric-value-description'">{{
            metricValue.description
        }}</span>
        <b-popover
            v-if="metricValue.description?.length > 40"
            :target="metricValue.id + 'metric-value-description'"
            placement="right"
            triggers="hover"
        >
            <span class="bold">{{ metricValue.description }}</span>
        </b-popover>
    </td>
</template>

<script>
export default {
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
        sort: {
            type: String,
            required: false,
        },
    },
};
</script>
