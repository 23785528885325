<template>
    <b-navbar-nav class="align-items-center ml-auto">
        <language-selector />
        <base-dropdown
            menu-on-right
            class="nav-item"
            tag="li"
            title-tag="a"
            title-classes="nav-link pr-0"
        >
            <a
                href="#"
                class="nav-link pr-0"
                @click.prevent
                slot="title-container"
            >
                <b-media
                    no-body
                    class="align-items-center"
                    v-if="user.profile_picture"
                >
                    <span class="avatar avatar-sm rounded-circle">
                        <img
                            alt="Profile Picture"
                            :src="user.profile_picture"
                        />
                    </span>
                    <b-media-body class="ml-2 d-none d-lg-block">
                    </b-media-body>
                </b-media>
                <b-avatar
                    v-else
                    variant="primary"
                    class="cc-badge-yellow"
                    :text="userInitials"
                ></b-avatar>
            </a>
            <template>
                <b-dropdown-header class="noti-title">
                    <h6 class="text-overflow m-0">{{ $t("welcome") }}</h6>
                </b-dropdown-header>
                <b-dropdown-item @click.prevent="goTo('/profile')">
                    <i class="ni ni-single-02"></i>
                    <span>{{ $t("my_profile") }}</span>
                </b-dropdown-item>
                <!-- <b-dropdown-item @click.prevent="openSupport">
                    <i class="ni ni-support-16"></i>
                    <span>Support</span>
                </b-dropdown-item> -->
                <div class="dropdown-divider"></div>
                <b-dropdown-item
                    @click.prevent="openSelectCompanyModal"
                    v-show="showSwitchCompany"
                >
                    <i class="ni ni-building"></i>
                    <span>{{ $t("switch_company") }}</span>
                    <switch-company-modal @show="showSwitchCompany = true" />
                </b-dropdown-item>
                <b-dropdown-item @click.prevent="logout">
                    <i class="ni ni-user-run"></i>
                    <span>{{ $t("logout") }}</span>
                </b-dropdown-item>
            </template>
        </base-dropdown>
    </b-navbar-nav>
</template>

<script>
import LanguageSelector from "@/components/elements/LanguageSelector.vue";
import SwitchCompanyModal from "@/components/navigation/switchCompany/SwitchCompanyModal.vue";
import AuthMixin from "@/mixins/auth/AuthMixin";
import { mapState } from "vuex";

export default {
    components: { SwitchCompanyModal, LanguageSelector },
    mixins: [
        AuthMixin, // logout
    ],
    data() {
        return {
            showSwitchCompany: false,
            supportLink: "https://intercom.help/futureproofed_for_businesses",
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        userInitials() {
            if (!this.user.first_name || !this.user.last_name) return "CC";
            return `${this.user.first_name.substring(
                0,
                1
            )}${this.user.last_name.substring(0, 1)}`;
        },
    },
    methods: {
        goTo(value) {
            this.$router.push(value);
        },
        openSelectCompanyModal() {
            this.$bvModal.show("modal-switch-company");
        },
        openSupport() {
            window.open(this.supportLink, "_blank");
        },
    },
};
</script>
