var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cc-input__wrapper"},[((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:_vm.customRef,staticClass:"cc-input",class:[
            _vm.size === 'lg' ? 'cc-input--large' : '',
            _vm.error ? 'cc-input--error' : '',
            _vm.underline ? 'cc-input--underline' : '',
            _vm.warning ? 'cc-input--warning' : '',
            _vm.customClass,
        ],attrs:{"min":_vm.min,"max":_vm.max,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled,"readonly":_vm.readonly,"id":_vm.customId,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')},"focus":function($event){return _vm.$emit('focus')},"keydown":function($event){return _vm.$emit('keydown', $event)},"change":function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:_vm.customRef,staticClass:"cc-input",class:[
            _vm.size === 'lg' ? 'cc-input--large' : '',
            _vm.error ? 'cc-input--error' : '',
            _vm.underline ? 'cc-input--underline' : '',
            _vm.warning ? 'cc-input--warning' : '',
            _vm.customClass,
        ],attrs:{"min":_vm.min,"max":_vm.max,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled,"readonly":_vm.readonly,"id":_vm.customId,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')},"focus":function($event){return _vm.$emit('focus')},"keydown":function($event){return _vm.$emit('keydown', $event)},"change":function($event){_vm.inputValue=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:_vm.customRef,staticClass:"cc-input",class:[
            _vm.size === 'lg' ? 'cc-input--large' : '',
            _vm.error ? 'cc-input--error' : '',
            _vm.underline ? 'cc-input--underline' : '',
            _vm.warning ? 'cc-input--warning' : '',
            _vm.customClass,
        ],attrs:{"min":_vm.min,"max":_vm.max,"placeholder":_vm.placeholder,"required":_vm.required,"disabled":_vm.disabled,"readonly":_vm.readonly,"id":_vm.customId,"type":_vm.type},domProps:{"value":(_vm.inputValue)},on:{"blur":function($event){return _vm.$emit('blur')},"click":function($event){return _vm.$emit('click')},"focus":function($event){return _vm.$emit('focus')},"keydown":function($event){return _vm.$emit('keydown', $event)},"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),_c('i',{staticClass:"text-gray-500 cc-input__append",class:[
            _vm.appendIcon,
            _vm.appendIconClickable ? 'cc-input__append--clickable' : '',
        ],on:{"click":function($event){$event.stopPropagation();return _vm.$emit('append-icon-clicked')}}}),(_vm.appendText)?_c('div',{staticClass:"cc-input__append cc-input__append--text"},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }