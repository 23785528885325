<template>
    <b-card @click="handleClick" class="cursor-pointer">
        <b-card-text class="position-relative">
            <h4 class="light text-gray-500 mb-0">
                {{ $t("dashboard_validation_card_header") }}
            </h4>
            <div class="card__icon">
                <i class="fa-solid fa-check text-success text-massive"></i>
            </div>
            <p class="text-massive m-0">{{ Math.round(pctValidated) }}%</p>
        </b-card-text>
    </b-card>
</template>

<script>
import insightsApi from "@/api/insights";
import { mapState } from "vuex";
import * as types from "@/store/types";

export default {
    data() {
        return {
            pctValidated: 0,
            pctValidatedBiogenic: 0,
        };
    },
    computed: {
        ...mapState({
            filters: (state) => state.filters,
        }),
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getValidationData();
        },
        async getValidationData() {
            try {
                const response = await insightsApi.validation(
                    this.filters.period,
                    false
                );
                this.pctValidated = response.data.pctValidated;
                this.pctValidatedBiogenic = response.data.pctValidatedBiogenic;
            } catch (error) {
                console.log(error);
            }
        },
        handleClick() {
            this.$store.dispatch(types.SET_FILTER, { validation: 0 });
            this.$router.push("data-input");
        },
    },
    watch: {
        filters: {
            handler() {
                this.getValidationData();
            },
        },
    },
};
</script>
