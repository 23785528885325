<template>
    <b-toast id="toast">
        {{ toast }}
    </b-toast>
</template>

<script>
import { mapState } from "vuex";
import * as types from "@/store/types";

export default {
    name: "CCToast",
    computed: {
        ...mapState({
            toast: (state) => state.toast,
        }),
    },
    methods: {
        showToast() {
            if (this.toast === "") return;

            this.$bvToast.toast(this.toast, {
                toaster: "b-toaster-bottom-left",
                autoHideDelay: 5000,
                bodyClass: "cc-toast__body",
                headerClass: "cc-toast__header",
                toastClass: "cc-toast__wrapper",
            });

            setTimeout(() => {
                this.$store.dispatch(types.SET_TOAST, "");
            }, 5050);
        },
    },
    watch: {
        toast: {
            handler() {
                this.showToast();
            },
        },
    },
};
</script>
