<template>
    <cc-select
        v-model="selectedLanguage"
        :options="languageOption"
        @input="changeLanguage"
        customClasses="cc-select--borderless"
    >
    </cc-select>
</template>

<script>
export default {
    name: "LanguageSelector",

    data() {
        return {
            selectedLanguage:
                localStorage.getItem("locale") ?? this.$i18n.locale,
            languageOption: [
                { value: "en", text: "English" },
                { value: "de", text: "Deutsch" },
                { value: "es", text: "Español" },
                { value: "fr", text: "Français" },
            ],
        };
    },

    methods: {
        changeLanguage() {
            // Update the i18n locale
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem("locale", this.selectedLanguage);
        },
    },
};
</script>
