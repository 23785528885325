// mixins/badgeTextMixin.js
export default {
    methods: {
        getBuBadgeText(metricValue) {
            const businessUnitId = metricValue.business_unit_id;
            const siteId = metricValue.site_id;
            const businessUnitName =
                businessUnitId === null
                    ? this.$t("all_business_units")
                    : this.getBuName(businessUnitId);

            return businessUnitId === null && siteId === null
                ? this.$t("all_business_units")
                : businessUnitId === null && businessUnitName && siteId !== null
                ? this.$t("badge_no_bu")
                : businessUnitName;
        },

        getSitesBadgeText(metricValue) {
            const siteId = metricValue.site_id;
            const businessUnitId = metricValue.business_unit_id;
            const siteName =
                siteId === null
                    ? this.$t("all_sites")
                    : this.getSiteName(siteId);

            return siteId === null && businessUnitId === null
                ? this.$t("all_sites")
                : siteName && siteId === null && businessUnitId !== null
                ? this.$t("badge_no_site")
                : siteName;
        },
    },
};
