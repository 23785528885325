<template>
    <!-- FORM -->
    <b-form @submit.prevent="handleFormSubmit">
        <!--form badges -->
        <span
            :class="
                modal
                    ? 'custom-metric__modal-item-container'
                    : 'd-flex flex-column'
            "
        >
            <span class="custom-metric__modal-value-item">
                <label for="site">{{ $t("label_site") }}</label>
                <b-badge
                    id="site"
                    variant="primary"
                    x
                    class="badge--blue badge--noclick mb-3"
                    v-if="localData.site_id"
                    >{{ getSite(localData.site_id) }}</b-badge
                >
                <b-badge
                    id="site"
                    variant="primary"
                    class="mb-3"
                    v-else-if="localData.site_id === null"
                    >{{ $t("badge_no_site") }}</b-badge
                >
            </span>

            <span class="custom-metric__modal-value-item">
                <label for="bu">{{ $t("label_bu") }}</label>
                <b-badge
                    id="bu"
                    class="badge--blue badge--noclick mb-3"
                    v-if="localData.business_unit_id"
                    >{{ getBu(localData.business_unit_id) }}</b-badge
                >
                <b-badge
                    id="site"
                    class="mb-3"
                    v-else-if="localData.business_unit_id === null"
                    >{{ $t("badge_no_bu") }}</b-badge
                >
            </span>
        </span>
        <!-- value input -->
        <b-form-group
            :class="modal ? 'container p-0 pr-1' : ''"
            id="custom_metric_value"
            :label="$t('label_value')"
            label-for="value"
            :invalid-feedback="$t('cm_value_invalid-feedback')"
            :state="valueState"
        >
            <div class="d-flex justify-content-between align-items-center">
                <cc-input
                    id="value"
                    type="text"
                    :placeholder="$t('enter_metric_value')"
                    v-model="localData.value"
                    @blur="handleFormSubmit('value')"
                    class="w-100"
                    appendText
                    :error="!valueState"
                >
                    {{ unitDetails }}
                </cc-input>
                <span class="ml-3">
                    <saving-status-loader
                        :savedStatus="savedStatus.value"
                        :saving="saving.value"
                    />
                </span>
            </div>
        </b-form-group>

        <!-- description input -->
        <b-form-group
            :class="modal ? 'container p-0 pl-1' : ''"
            id="custom-metric-description"
            :label="$t('label_comments')"
            label-for="description"
            :invalid-feedback="$t('cm_description_invalid-feedback')"
            :state="descriptionState"
        >
            <span class="d-flex align-items-center">
                <b-form-textarea
                    id="description"
                    v-model="localData.description"
                    :placeholder="$t('enter_metric_description')"
                    rows="5"
                    @blur="handleFormSubmit('description')"
                    :state="descriptionState"
                ></b-form-textarea>
                <span class="pl-3">
                    <saving-status-loader
                        :savedStatus="savedStatus.description"
                        :saving="saving.description"
                    />
                </span>
            </span>
        </b-form-group>

        <MetricAttachments :customMetricValueId="metricValue.id" />
    </b-form>
</template>

<script>
import customMetricsApi from "@/api/custom_metrics";
import SavingStatusLoader from "@/components/elements/SavingStatusLoader";
import { mapState } from "vuex";
import * as types from "@/store/types";
import MetricAttachments from "./MetricAttachments.vue";

const newForm = {
    file: null,
    value: null,
    description: "",
    business_unit_id: null,
    site_id: null,
    period_id: null,
};

export default {
    name: "MetricValueDataInput",
    components: {
        SavingStatusLoader,
        MetricAttachments,
    },
    props: {
        metricValue: {
            type: Object,
            required: true,
            default: () => structuredClone(newForm),
        },
        modal: {
            type: Boolean,
            required: false,
            default: false,
        },
        unitDetails: {
            type: String,
            required: true,
            default: "",
        },
    },
    data() {
        return {
            saving: {
                value: false,
                description: false,
            },
            savedStatus: {
                value: "",
                description: "",
            },
        };
    },
    computed: {
        ...mapState({
            sites: (state) => state.data.sites,
            businessUnits: (state) => state.data.businessUnits,
        }),
        valueState() {
            return !isNaN(+this.localData?.value);
        },
        descriptionState() {
            return (
                !this.localData?.description ||
                (this.localData?.description?.length > 1 &&
                    this.localData?.value?.length > 1)
            );
        },
        localData: {
            get() {
                return this.metricValue;
            },
            set(value) {
                this.$emit("input-update", value);
            },
        },
    },

    methods: {
        getSite(id) {
            return this.sites.find((x) => x.id === id)?.name;
        },

        getBu(id) {
            return this.businessUnits.find((x) => x.id === id)?.name;
        },

        // Triggers on every input blur or form submit
        handleFormSubmit(updatedField) {
            // Set saving fields: full form submit updates all fields, otherwise only the updated field
            if (!updatedField) {
                Object.keys(this.saving).forEach((key) => {
                    this.saving[key] = true;
                });
            } else {
                this.saving[updatedField] = true;
            }

            // Save the data, if there is no ID, create a new one
            if (this.localData.id === null) {
                this.createMetricValue(updatedField);
            } else {
                this.updateMetricValue(updatedField);
            }
        },

        // API CALLS
        async createMetricValue() {
            try {
                const response = await customMetricsApi.createCustomMetricValue(
                    this.localData?.metric_id,
                    this.localData
                );

                this.handleSaveStatus();

                const result = response.data.data;
                if (result) {
                    this.localData.id = result.id;
                    this.$store.dispatch(
                        types.SET_TOAST,
                        this.$t("al_created_cmv")
                    );
                }
                this.$emit("refresh");
            } catch (error) {
                console.log(error);
                this.handleSaveStatus(error);
            }
        },

        async updateMetricValue() {
            try {
                const response = await customMetricsApi.updateCustomMetricValue(
                    this.localData?.metric_id,
                    this.localData,
                    this.localData?.id
                );

                this.handleSaveStatus();

                const result = response.data.data;
                if (result) {
                    this.$store.dispatch(
                        types.SET_TOAST,
                        this.$t("al_updated_cmv")
                    );
                }
                this.$emit("refresh");
            } catch (error) {
                console.log(error);
                this.handleSaveStatus(error);
            }
        },

        // SAVED INDICATOR HANDLER
        handleSaveStatus(error) {
            Object.keys(this.saving).forEach((key) => {
                this.saving[key] = false;
                this.savedStatus[key] = error ? "error" : "success";
            });
        },
    },
};
</script>
