/* eslint-disable */
import store from "@/store";

let accountTracked = false;

export const load = () => {
    const vitally = window.Vitally;

    if (typeof vitally === "object") return;

    const init = () => {
        !function (n, t, r) { for (var i = n[t] = n[t] || [], o = function (r) { i[r] = i[r] || function () { for (var n = [], t = 0; t < arguments.length; t++)n[t] = arguments[t]; return i.push([r, n]) } }, u = 0, c = ["init", "user", "account", "track"]; u < c.length; u++) { o(c[u]) } }(window, "Vitally");
        const result = Vitally.init('ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lJek1XWTNZVFUyWmkwNU5tTXpMVFJsT1dJdFlqZ3pOUzFqTnpNd01UaGxZbVJrWXpraUxDSmhjR2tpT2lJMk5UWmtPR1V3T0MxaFpEVTBMVFJtTVRBdE9UTTFNaTAxT1RRNE1HUTRPVFk1TldFaUxDSnpaV01pT2lKaE5EazRaV0kxWWlKOS5UVGZTaGdMLVJNbWhlQkJ4RUEwNEgwVk9nZ3VncGswTkVqbmFJUk1keXlNOg==');
    }

    var l = () => {
        var s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://cdn.vitally.io/vitally.js/v1/vitally.js";
        var x = document.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
        init();
    };
    if (document.readyState === "complete") {
        l();
    } else if (w.attachEvent) {
        window.attachEvent("onload", l);
    } else {
        window.addEventListener("load", l, false);
    }
}

const track = async (event, properties = {}) => {
    const company = store.state.company;
    const user = store.state.user;

    // If no company or user to report, so don't bother
    if (!company || !user) return;
    // If a futureproofed email, don't bother
    if (user.email.includes("@futureproofed.com")) return;
    // Don't track support users
    if (user.role === "support") return;
    // If not in production, don't bother
    if (process.env.NODE_ENV !== "production") return;
    
    try {
        if (!accountTracked) {
            await Vitally.account({
                accountId: `FPB-${company.id}`,
                traits: {
                    name: company.name,
                    status: company.status,
                },
            });
            accountTracked = true;
        }
        
        await Vitally.user({
            userId: `FPB-${user.id}`,
            accountId: `FPB-${company.id}`,
            traits: {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                role: user.role,
            },
        });

        await Vitally.track({
            event,
            userId: `FPB-${user.id}`,
            properties: {
                ...properties,
            },
        });
    } catch (error) {
        console.log(error);
    }
    
};

export default {
    install: (Vue) => {
        Vue.prototype.$vitally = (event, properties) => track(event, properties);
    },
};
