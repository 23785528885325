<template>
    <td :class="sort === 'bu' ? 'cc-table__column--highlight' : ''">
        <span
            class="badge badge-pill"
            :style="getBadgeStyle(metricValue.business_unit_id)"
            :class="metricValue.business_unit_id === null ? 'badge--empty' : ''"
        >
            <span>{{ getBuBadgeText(metricValue) }}</span>
        </span>
    </td>
</template>

<script>
import BadgeTextMixin from "@/mixins/custom_metrics/BadgeTextMixin";
export default {
    mixins: [BadgeTextMixin],
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
        businessUnits: {
            type: Array,
            required: true,
        },
        sort: {
            type: String,
            required: false,
        },
    },
    methods: {
        getBadgeStyle(buId) {
            const bu = this.businessUnits.find((x) => x.id === buId);
            if (bu === undefined) return "";

            const colour = bu.colour;
            if (colour) {
                return `color: ${colour}; background-color: ${colour}10; box-shadow: none;`;
            }
        },
        getBuName(id) {
            if (!id) return;
            const bu = this.businessUnits.find((x) => x.id === id);

            if (bu) return bu.name;
            else return this.$t("al_unknown");
        },
    },
};
</script>
