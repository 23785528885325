<template>
    <div
        class="row position-relative d-flex align-items-center m-0 p-0 justify-content-center justify-content-xl-start"
    >
        <div class="d-flex align-items-center">
            <h2 class="text-huge regular text-nowrap">{{ title }}</h2>
            <transition name="bounce" mode="out-in">
                <i
                    id="category_button"
                    key="arrow"
                    class="far fa-chevron-down text-bigger ml-4 text-gray-600 cursor-pointer send-to-top"
                    v-if="!showFilters"
                    @click="handleShowFilters"
                ></i>
                <i
                    key="cross"
                    class="fal fa-times text-bigger ml-4 text-gray-600 cursor-pointer send-to-top"
                    v-else
                    @click="handleShowFilters"
                ></i>
            </transition>
        </div>

        <div>
            <b-card
                class="insights__filters shadow-sm"
                :class="showFilters ? 'insights__filters--visible' : ''"
            >
                <ul class="insights__filters-list">
                    <li v-for="item in items" :key="item.id">
                        <button
                            type="button"
                            class="btn btn-link btn-secondary"
                            @click="selectItem(item)"
                        >
                            {{ $t(item.name) }}
                        </button>
                    </li>
                </ul>
            </b-card>
            <b-tooltip target="category_button" triggers="hover">
                {{ $t("select_category") }}
            </b-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            showFilters: false,
        };
    },
    methods: {
        handleShowFilters() {
            this.showFilters = !this.showFilters;
        },
        selectItem(item) {
            this.handleShowFilters();
            this.$emit("item-selected", item);
        },
    },
};
</script>
