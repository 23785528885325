<template>
    <div>
        <b-row class="mt-4">
            <b-col sm="12" md="4">
                <stats-card
                    type=""
                    icon="fas fa-smoke text-white"
                    class="bg-dashboard-1 text-white"
                >
                    <h5 class="card-title text-white mb-0 light">
                        {{ $t("dashboard_sc_total_emissions") }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0 text-white">
                        {{ totalEmissions }}
                    </span>
                    <template slot="footer">
                        <span class="text-nowrap text-white light">tCO₂e</span>
                    </template>
                </stats-card>
            </b-col>
            <b-col sm="12" md="4">
                <stats-card
                    type=""
                    icon="fas fa-user text-white"
                    class="bg-dashboard-2 text-white"
                >
                    <h5 class="card-title text-white mb-0 light">
                        {{ $t("dashboard_sc_emissions_fte") }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0 text-white">
                        {{ totalEmissionsPerFTE }}
                    </span>
                    <template slot="footer">
                        <span class="text-nowrap text-white light">tCO₂e</span>
                    </template>
                </stats-card>
            </b-col>
            <b-col sm="12" md="4">
                <stats-card
                    type=""
                    icon="fas fa-euro text-white"
                    class="bg-dashboard-3 text-white"
                >
                    <h5 class="card-title text-white mb-0 light">
                        {{ $t("dashboard_sc_emissions_per_milion") }}
                    </h5>
                    <span class="h2 font-weight-bold mb-0 text-white">
                        {{ totalEmissionsPerMillionEuro }}
                    </span>
                    <template slot="footer">
                        <span class="text-nowrap text-white light">tCO₂e</span>
                    </template>
                </stats-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        totalEmissions: {
            type: [String, Number],
            required: true,
        },
        totalEmissionsPerFTE: {
            type: [String, Number],
            required: true,
        },
        totalEmissionsPerMillionEuro: {
            type: [String, Number],
            required: true,
        },
    },
};
</script>
