export const createFormattedTime = (date) => {
    // Get the current timestamp
    const now = Date.now();
    // Create the timestamp for the input date
    const inputDate = new Date(date).getTime();
    // Check the timezone and calculate the offset
    const offSet = new Date(date).getTimezoneOffset() * 60 * 1000;
    // Calculate the difference between the two timestamps
    const timeDiff = now - inputDate + Math.abs(offSet);
    // Get the locale
    const locale = Intl.NumberFormat().resolvedOptions().locale;

    if (timeDiff < 0)
        return new Date(inputDate).toLocaleString(locale, { timeZone: "UTC" });
    if (timeDiff < 10000) return `Just now`;
    if (timeDiff < 60000) return `A few seconds ago`;
    if (timeDiff < 300000) return `A few minutes ago`;
    if (timeDiff < 3600000) return `Within the last hour`;
    if (timeDiff < 86400000) return `Less than a day ago`;
    else
        return new Date(inputDate).toLocaleString(locale, {
            timeZone: "UTC",
        });
};

const formatTime = {
    install: (Vue) => {
        Vue.prototype.$formatTime = (date) => createFormattedTime(date);
    },
};

export default formatTime;
