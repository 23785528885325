<template>
    <div class="custom-metric-sidebar__header">
        <div>
            <h2 class="mb-0">{{ title }}</h2>
        </div>
        <div class="mr-2">
            <b-button
                id="maximize_button"
                variant="link"
                @click="$emit('open-modal')"
                class="font-weight-bold"
            >
                <i
                    class="fa-solid fa-up-right-and-down-left-from-center h3 text-gray-500"
                ></i>
            </b-button>
            <b-tooltip target="maximize_button" triggers="hover">
                {{ $t("maximize") }}
            </b-tooltip>
            <b-button-close @click="$emit('handle-cancel')"></b-button-close>
        </div>
    </div>
</template>

<script>
export default {
    name: "MetricSidebarHeader",
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>
