<template>
    <b-modal
        id="modal-switch-company"
        :title="$t('select_company')"
        ok-title="Save"
        @ok="save"
    >
        <slot></slot>
        <b-select
            id="switch-company"
            v-model="companySelected"
            :options="sortedCompanies"
        ></b-select>
    </b-modal>
</template>

<script>
import { mapState } from "vuex";

import * as types from "@/store/types";

export default {
    data() {
        return {
            companySelected: "",
            sortedCompanies: [],
        };
    },
    computed: {
        ...mapState({
            company: (state) => state.company,
            companies: (state) => state.companies,
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.company) return;

            this.companySelected =
                sessionStorage.getItem("selectedCompany") || this.company.id;

            const sortedCompanies = this.companies.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            this.sortedCompanies = sortedCompanies.map((c) => {
                let companyText = c.name;
                if (c.status === "trial") companyText = `${c.name} - trial`;
                return { value: c.id, text: companyText };
            });
            if (this.sortedCompanies.length > 1) this.$emit("show");
        },
        save() {
            // Reset all filters first, prevent errors after changing account
            this.$store.dispatch(types.RESET_FILTERS);
            sessionStorage.setItem("selectedCompany", this.companySelected);

            this.$router.go(); // refresh page
        },
    },
    watch: {
        company: {
            handler(val) {
                if (val) this.init();
            },
        },
    },
};
</script>
