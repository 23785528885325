<template>
    <div>
        <div v-show="!hideGraph">
            <!-- CHART -->
            <div d3-chart :id="chartKey" ref="chart"></div>

            <!-- LEGEND -->
            <div
                class="d-flex justify-content-center align-items-center flex-wrap"
            >
                <span
                    v-for="item in data"
                    :key="`${item[idKey]}-${item[labelKey]}`"
                    class="badge badge-pill mx-3 d3-pie-chart__legend"
                    :class="
                        item[idKey] === hovered
                            ? 'd3-pie-chart__legend--hovered shadow'
                            : ''
                    "
                    :style="
                        colourKey
                            ? `color: ${item[colourKey]}; background-color: ${item[colourKey]}10;`
                            : ''
                    "
                    @click="$emit('arc-clicked', item)"
                >
                    {{ item[labelKey] }}
                </span>
            </div>
        </div>
        <div v-if="hideGraph" class="ml-2 text-gray-500 text-tiny">
            {{ $t("charts_no_data") }}
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        labelKey: {
            type: String,
            required: true,
        },
        idKey: {
            type: String,
            required: true,
        },
        colourKey: {
            type: String,
            required: false,
        },
        chartKey: {
            type: String,
            required: false,
            default: "d3-chart",
        },
        width: {
            type: Number,
            required: false,
            default: 400,
        },
        height: {
            type: Number,
            required: false,
            default: 400,
        },
    },
    data() {
        return {
            svg: null,
            hovered: null,
            radius: 0,
        };
    },
    computed: {
        hideGraph() {
            // Graph should be hidden if there is 0 emissions to be shown.
            let emissions = 0;
            this.data.forEach((item) => {
                emissions += item.emissions;
            });

            return emissions === 0;
        },
    },
    mounted() {
        this.generateChart();
    },
    methods: {
        generateChart() {
            // Calculation method: Math.min(width, height) / 2 - margin;
            this.radius = this.width / 2 - 40;

            // Create the SVG and position everything in the center
            this.svg = d3
                .select("#" + this.chartKey)
                .append("svg")
                .attr("viewBox", `0 0 ${this.width} ${this.height}`)
                .append("g")
                .attr("transform", `translate(200, 200)`);

            // Point the pie graph to the emissions data point
            const pie = d3.pie().value((data) => data.emissions);
            const data_ready = pie(this.data);

            // Create the SVG
            this.svg
                .selectAll("*")
                .data(data_ready)
                .join("path")
                .attr("d", d3.arc().innerRadius(0).outerRadius(this.radius))
                .attr("fill", (data) => data.data[this.colourKey])
                .attr("stroke", "white")
                .attr("id", (data) => `arc-${data.data[this.idKey]}`)
                .style("stroke-width", "4px")
                .on("mouseover", (e, data) => this.highlightPie(data.data))
                .on("mouseout", (e, data) => {
                    this.addHoverData(e, null);
                    this.removeHighlight(data.data);
                })
                .on("mousemove", (e, data) => this.addHoverData(e, data.data))
                .on("mouseup", (e, data) =>
                    this.$emit("arc-clicked", data.data)
                )
                .classed("d3-pie-chart__arc", true);
        },

        addHoverData(e, data) {
            this.hovered = null;
            const coords = d3.pointer(e);
            this.svg.selectAll(".hover-data, .hover-data__background").remove();

            if (data !== null) {
                this.hovered = data[this.idKey];
                const hoverbox = this.svg
                    .append("rect")
                    .attr("fill", "white")
                    .attr("height", 30)
                    .attr("stroke", "black")
                    .attr("rx", 5)
                    .classed("hover-data__background", true);
                const hovertext = this.svg
                    .append("text")
                    .attr(
                        "transform",
                        `translate(${coords[0] + 10}, ${coords[1] - 20})`
                    )
                    .attr("text-anchor", coords[0] < 0 ? "start" : "end")
                    .classed("hover-data", true)
                    .text(
                        `${this.$checkDecimals(data.emissions)} ${data.unit}`
                    );
                const hovertextWidth = hovertext._groups[0][0].getBBox().width;
                hoverbox
                    .attr("width", hovertextWidth + 20)
                    .attr(
                        "transform",
                        "translate(" +
                            (coords[0] < 1
                                ? coords[0]
                                : coords[0] - hovertextWidth) +
                            "," +
                            (coords[1] - 40) +
                            ")"
                    );
            }
        },
        highlightPie(data) {
            const element = document.getElementById(`arc-${data[this.idKey]}`);
            element.setAttribute("fill-opacity", ".5");
        },
        removeHighlight(data) {
            const element = document.getElementById(`arc-${data[this.idKey]}`);
            element.setAttribute("fill-opacity", 1);
        },
    },
};
</script>
