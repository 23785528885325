<template>
    <div id="app" v-if="isIE === false">
        <cc-side-bar v-if="$auth.isAuthenticated && user" />

        <div class="main-content">
            <!-- Transition works fine but the header doesn't work well with it, wip -->
            <transition name="fade" mode="out-in">
                <router-view id="app_container" />
            </transition>
        </div>

        <Loader />

        <!-- Error catching modal -->
        <error></error>

        <!-- Toast -->
        <Toast />
    </div>
    <div id="app" v-else>
        <p id="outdated-browser">
            {{ $t("app_outdated_browser_text") }}
        </p>
    </div>
</template>

<script>
import intercom from "@/util/intercom";
import { load } from "@/util/vitally";

import AuthMixin from "@/mixins/auth/AuthMixin.js";
import CcSideBar from "@/components/navigation/sidebar/CcSideBar.vue";
import Error from "@/components/pages/Error.vue";
import Toast from "@/components/elements/Toast.vue";
import Loader from "@/components/elements/Loader.vue";

import { mapState } from "vuex";
import * as types from "@/store/types";

export default {
    components: {
        CcSideBar,
        Error,
        Toast,
        Loader,
    },
    mixins: [
        AuthMixin, // login, logout
    ],
    data() {
        return {
            isIE: false,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            company: (state) => state.company,
        }),
    },
    created() {
        this.IECheck();
        this.$amplitude.logEvent("LOADED_APP");
    },
    methods: {
        init() {
            const unAuthenticatedRoutes = [
                "Login",
                "Invite",
                "StartTrial",
                "Welcome",
                "NoAccess",
            ];

            // We need to hide the loader when we're showing a page that doesn't require authentication
            if (
                unAuthenticatedRoutes.includes(this.$route.name) ||
                !this.$route.name
            ) {
                this.$store.dispatch(types.SHOW_LOADER, false);
            }
        },
        initExternalServices() {
            if (process.env.NODE_ENV === "production") {
                intercom.load();
                intercom.boot({
                    ...this.user,
                    user_id: `FPB-${this.user.id}`,
                    company_id: `FPB-${this.company.id}`,
                });

                load();
                this.$vitally("loaded_app");

                this.$amplitude.setUserId(this.user.id);
            } else {
                this.$amplitude.setUserId(`DEV:${this.user.id}`);
            }
        },
        IECheck() {
            this.isIE =
                !!window.MSInputMethodContext && !!document.documentMode;
        },
    },
    watch: {
        user: {
            handler(val) {
                if (val) this.initExternalServices();
            },
        },
        "$auth.loading": {
            handler(val) {
                if (!val) this.init();
            },
        },
        $route: {
            handler() {
                if (this.preloaded) return;
                this.init();
            },
        },
    },
};
</script>
