<template>
    <div>
        <b-row>
            <b-col cols="12" md="8" class="p-2">
                <b-card>
                    <template #header>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emission_evolution") }}</h2>
                        </div>
                    </template>

                    <transition mode="out-in" name="slow-fade">
                        <D3Bar
                            :data="buEvolution"
                            chartKey="buEvolution"
                            labelKey="period_name"
                            idKey="period_id"
                            :highlighted="filters.period"
                            @bar-clicked="handleChartClicked"
                            v-if="!loading"
                        ></D3Bar>
                        <b-skeleton-img v-else />
                    </transition>
                </b-card>

                <b-card>
                    <template #header>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emissions_per_category") }}</h2>
                        </div>
                    </template>

                    <transition mode="out-in" name="slow-fade">
                        <D3HorizontalBar
                            :data="catData"
                            chartKey="catData"
                            labelKey="cat_name"
                            idKey="cat_id"
                            @bar-clicked="handleChartClicked"
                            barStyle="bar__green"
                            v-if="!loading"
                        ></D3HorizontalBar>

                        <b-skeleton-img v-else />
                    </transition>
                </b-card>

                <b-card>
                    <template #header>
                        <div class="ml-2">
                            <p class="text-mini text-gray-500 m-0">
                                {{ $t("in_ton") }}
                            </p>
                            <h2>{{ $t("emissions_per_source") }}</h2>
                        </div>
                    </template>

                    <transition mode="out-in" name="slow-fade">
                        <D3HorizontalBar
                            :data="sourceData"
                            chartKey="sourceData"
                            labelKey="name"
                            idKey="emission_source_id"
                            barStyle="bar__green"
                            v-if="!loading"
                        ></D3HorizontalBar>

                        <b-skeleton-img v-else />
                    </transition>
                </b-card>
            </b-col>
            <b-col cols="12" md="4" class="p-2">
                <stats-card
                    type=""
                    icon="fas fa-smoke stats-card__icon"
                    iconClasses="text-white"
                    class="bg-dashboard-2 text-white px-4 py-2"
                >
                    <h3 class="card-title text-white mb-1 light">
                        {{ $t("total_emissions") }}
                    </h3>
                    <div>
                        <span class="h1 font-weight-bold mb-0 text-white">
                            {{ total }}
                        </span>
                        <span
                            class="text-nowrap text-tiny ml-3 text-white light"
                            >{{ $t("ton_co2e") }}</span
                        >
                    </div>

                    <template slot="footer">
                        <div class="d-flex justify-content-between">
                            <span
                                class="text-nowrap color-success light"
                            ></span>
                            <span class="text-nowrap">
                                <b-button
                                    size="sm"
                                    variant="outline-light"
                                    @click="$router.push('/data-input')"
                                >
                                    {{ $t("view_data") }}
                                    <i class="fas fa-chevron-right ml-2"></i>
                                </b-button>
                            </span>
                        </div>
                    </template>
                </stats-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import D3Bar from "@/components/charts/D3Bar";
import D3HorizontalBar from "@/components/charts/D3HorizontalBar";
import InsightsMixin from "../mixins/InsightsMixin.vue";

export default {
    mixins: [InsightsMixin],
    components: {
        D3Bar,
        D3HorizontalBar,
    },
    data() {
        return {
            buEvolution: [],
            catData: [],
            sourceData: [],
            loading: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            this.getTotal();
            this.loading = true;
            const buEvolution = this.getBuEvolution();
            const catData = this.getCatData();
            const sourceData = this.getSourceData();
            await Promise.all([buEvolution, catData, sourceData]);
            this.loading = false;
        },
    },
};
</script>
