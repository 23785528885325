<template>
    <div>
        <b-card fluid>
            <!-- Accordion table header -->
            <div class="d-flex align-items-center justify-content-end pb-3">
                <cc-select
                    v-if="customMetric.global_site === 0"
                    v-model="filteredSite"
                    :options="selectSites"
                    class="custom-metric__select-item mr-3"
                >
                </cc-select>
                <cc-select
                    v-if="customMetric.global_business_unit === 0"
                    v-model="filteredBu"
                    :options="selectBu"
                    class="custom-metric__select-item"
                >
                </cc-select>
            </div>
            <!-- Accordion table  -->
            <b-skeleton-wrapper :loading="loading">
                <template #loading>
                    <b-row v-for="(field, i) in fields" :key="`load-${i}`">
                        <b-col
                            ><b-skeleton
                                type="input"
                                class="w-90 my-2"
                            ></b-skeleton>
                        </b-col>
                    </b-row>
                </template>
                <div class="table-responsive">
                    <table class="table cc-table--hover" ref="metric-table">
                        <thead>
                            <tr>
                                <th
                                    v-for="field in fields"
                                    :key="field.key"
                                    @click="sortTable(field.column)"
                                >
                                    {{ field.label }}
                                    <span
                                        v-if="field.sortable"
                                        class="sort-icon"
                                    >
                                        <i
                                            v-if="query.sort === field.column"
                                            :class="iconClass"
                                        ></i>
                                        <i
                                            v-else
                                            class="fa-regular fa-arrow-up-arrow-down fa-xl"
                                        ></i>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, index) in metricValues">
                                <tr
                                    v-if="$can('read', 'metric_value')"
                                    :key="`metric-value-${index}`"
                                    @click.stop="
                                        handleSelectedCustomMetricValue(item)
                                    "
                                >
                                    <!-- metric value sites -->
                                    <MetricValueSite
                                        :metricValue="item"
                                        :sites="sites"
                                        :sort="sort"
                                    />
                                    <!-- metric value business units-->
                                    <MetricValueBU
                                        :metricValue="item"
                                        :businessUnits="businessUnits"
                                        :sort="sort"
                                    />
                                    <!-- metric value -->
                                    <MetricValue
                                        :metricValue="item"
                                        :sort="sort"
                                    />
                                    <!-- metric description -->
                                    <MetricValueDescription
                                        :metricValue="item"
                                        :sort="sort"
                                    />
                                    <!-- metric value attachments -->
                                    <MetricValueAttachment
                                        :metricValue="item"
                                        class="pr-5"
                                    />
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </b-skeleton-wrapper>
        </b-card>
    </div>
</template>

<script>
import customMetricsApi from "@/api/custom_metrics";
import sortSourcesUtil from "@/util/sort-sources.js";
import { mapState } from "vuex";
import MetricValue from "./tableColumns/MetricValue.vue";
import MetricValueAttachment from "./tableColumns/MetricValueAttachment.vue";
import MetricValueBU from "./tableColumns/MetricValueBU.vue";
import MetricValueDescription from "./tableColumns/MetricValueDescription.vue";
import MetricValueSite from "./tableColumns/MetricValueSite.vue";

export default {
    name: "MetricAccordionTable",
    components: {
        MetricValueSite,
        MetricValueBU,
        MetricValue,
        MetricValueDescription,
        MetricValueAttachment,
    },
    props: {
        customMetric: {
            type: Object,
            required: true,
            description: "The custom metric data.",
        },
        navigateMetricValue: {
            type: Number,
            required: false,
            default: 0,
            description: "Navigate to next or previous item",
        },
    },
    data() {
        return {
            filteredSite: null,
            filteredBu: null,
            metricValues: [],
            rowClass: null,
            title: "",
            loading: true,

            fields: [
                {
                    key: "site_id",
                    label: this.$t("cm_site_label"),
                    column: "site",
                    sortable: true,
                },
                {
                    key: "business_unit_id",
                    label: this.$t("label_bu"),
                    column: "business_unit",
                    sortable: true,
                },
                {
                    key: "value",
                    label: `${this.$t("label_value")} [${
                        this.customMetric.unit
                    }]`,
                    column: "value",
                    sortable: true,
                },
                {
                    key: "description",
                    label: this.$t("label_comments"),
                    column: "description",
                    sortable: true,
                },
                {
                    key: "attachments",
                    label: this.$t("attachments"),
                    class: "text-right pr-5",
                    sortable: false,
                },
            ],
            iconClass: "",
            query: {
                sort: null,
                desc: null,
            },
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapState({
            periodId: (state) => state.filters.period,
            sites: (state) => state.data.sites,
            sort: (state) => state.dataInput.sort,
            businessUnits: (state) => state.data.businessUnits,
        }),
        selectSites() {
            const siteIds = this.customMetric.siteIds.split(",");
            const sites = this.sites
                .map((x) => {
                    return { value: x.id, text: x.name };
                })
                .filter((x) => siteIds.includes(x.value.toString()));
            sites.unshift({
                value: null,
                text: this.$t("all_sites"),
            });
            return sites;
        },

        selectBu() {
            const buIds = this.customMetric.businessUnitIds.split(",");
            const bu = this.businessUnits
                .map((x) => {
                    return { value: x.id, text: x.name };
                })
                .filter((x) => buIds.includes(x.value.toString()));
            bu.unshift({
                value: null,
                text: this.$t("all_business_units"),
            });
            return bu;
        },
        period() {
            return this.$store.getters.getCurrentPeriod();
        },
    },
    methods: {
        init() {
            this.getCustomMetricValues(this.customMetric.id);
        },

        async getCustomMetricValues(metricId, sortParam) {
            try {
                this.loading = true;
                const response = await customMetricsApi.getCustomMetricValues(
                    metricId,
                    this.periodId,
                    sortParam
                );

                const items = this.addLocalIds(response.data.data);
                let filteredItems = [...items];

                // Apply filters based on selected site and business unit
                if (this.filteredSite) {
                    filteredItems = filteredItems.filter(
                        (item) => item.site_id === this.site
                    );
                }
                if (this.filteredBu) {
                    filteredItems = filteredItems.filter(
                        (item) => item.business_unit_id === this.businessUnit
                    );
                }

                this.metricValues = filteredItems;

                this.sortMetricValues();
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        //In here selected value gets identified and we are emitting event to parent component depending on value id
        handleSelectedCustomMetricValue(value) {
            if (!this.$can("update", "metric_value")) return;

            if (this.period.locked === 1) return;
            if (!value) return;

            this.$emit("select-metric-value", {
                metricValue: value,
                customMetric: this.customMetric,
            });
        },

        // Add local ID's, because some of the values have id = null
        addLocalIds(items) {
            return items.map((item) => {
                return {
                    ...item,
                    localId: `${item.metric_id}-${item.site_id}-${item.business_unit_id}`,
                };
            });
        },

        navigateValue(details) {
            const index = this.metricValues.findIndex((value) => {
                return value.localId === details.localId;
            });
            if (index === -1) return;

            this.handleSelectedCustomMetricValue(
                this.metricValues[index + details.direction]
            );
        },
        sortMetricValues() {
            if (this.sort) {
                this.metricValues = sortSourcesUtil(
                    this.metricValues,
                    this.sort,
                    this.sites,
                    this.businessUnits
                );
                return;
            }

            // Sort on localIds by default
            this.metricValues.sort((a, b) => {
                return a.localId.localeCompare(b.localId);
            });
        },
        sortTable(columnKey) {
            this.query.sort = columnKey;
            if (this.query.sort === columnKey) {
                this.query.desc = !this.query.desc;
            }
            this.query.desc
                ? (this.iconClass = "fa-duotone fa-arrow-up-arrow-down fa-xl")
                : (this.iconClass =
                      "fa-duotone fa-arrow-up-arrow-down fa-swap-opacity fa-xl");
        },
    },
    watch: {
        periodId: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.init();
            },
        },
        filteredSite: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.init();
            },
        },
        filteredBu: {
            handler(newVal, oldVal) {
                if (newVal === oldVal) return;
                this.init();
            },
        },
        sort: {
            handler() {
                this.sortSources();
            },
        },
        navigateMetricValue: {
            handler(newVal) {
                if (newVal === 1 || newVal === -1) {
                    this.navigateValue(newVal === 1);
                }
                this.$nextTick(() => {
                    this.$emit("reset-navigation");
                });
            },
        },
    },
};
</script>
