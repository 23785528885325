var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$can('list', 'invite'))?_c('b-card',{staticClass:"cc-content cc-content-card"},[_c('b-row',[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$t("team_invites_header")))])]),_c('b-col',{staticClass:"text-right"},[(_vm.$can('create', 'invite'))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.showInviteForm = true}}},[_vm._v(_vm._s(_vm.$t("team_invites_button_invite"))+" ")]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.showActive),callback:function ($$v) {_vm.showActive=$$v},expression:"showActive"}},[_c('span',{staticClass:"text-mini d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t("team_invites_checkbox"))+" ")])])],1)],1),_c('div',{staticClass:"mt-6"},[_c('b-table',{attrs:{"striped":"","hover":"","fields":_vm.invitesFields,"items":_vm.invites,"responsive":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_vm._v(" "+_vm._s(`${data.item.first_name} ${data.item.last_name}`)+" ")]}},{key:"cell(expires_at)",fn:function(data){return [_c('span',{class:_vm.expiredDate(data.item.expires_at)
                            ? 'text-warning'
                            : ''},[_vm._v(_vm._s(new Date(data.item.expires_at).toLocaleString()))])]}},{key:"cell(site_access)",fn:function(data){return [_c('div',[(
                            _vm.createSiteAccessArray(data.item.role_info)
                                .length > 0
                        )?_c('div',[_c('b-badge',{staticClass:"badge--blue badge--noclick",class:{
                                'badge--red': _vm.createSiteAccessArray(
                                    data.item.role_info
                                )[0].deleted,
                                'badge--blue': !_vm.createSiteAccessArray(
                                    data.item.role_info
                                )[0].deleted,
                            }},[_vm._v(" "+_vm._s(_vm.createSiteAccessArray( data.item.role_info )[0].name)+" ")]),(
                                _vm.createSiteAccessArray(data.item.role_info)
                                    .length > 1
                            )?_c('p',{staticClass:"text-gray-500 light text-small ml-1 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("team_function_text", { length: _vm.createSiteAccessArray( data.item.role_info ).length, }))+" ")]):_vm._e()],1):_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(" "+_vm._s(_vm.$t("all_sites"))+" ")])],1)]}},{key:"cell(business_access)",fn:function(data){return [_c('div',[(
                            _vm.createBusinessAccessArray(data.item.role_info)
                                .length > 0
                        )?_c('div',[_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(" "+_vm._s(_vm.createBusinessAccessArray( data.item.role_info )[0].name)+" ")]),(
                                _vm.createBusinessAccessArray(
                                    data.item.role_info
                                ).length > 1
                            )?_c('p',{staticClass:"text-gray-500 light text-small ml-1 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("and"))+" "+_vm._s(_vm.createBusinessAccessArray( data.item.role_info ).length - 1)+" "+_vm._s(_vm.$t("more"))+" ")]):_vm._e()],1):_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(" "+_vm._s(_vm.$t("all_business_units"))+" ")])],1)]}},{key:"cell(tag_access)",fn:function(data){return [_c('div',[(
                            _vm.createTagsAccessArray(data.item.role_info)
                                .length > 1
                        )?_c('div',[_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(" "+_vm._s(_vm.createTagsAccessArray( data.item.role_info )[0].title)+" ")]),(
                                _vm.createTagsAccessArray(data.item.role_info)
                                    .length > 1
                            )?_c('p',{staticClass:"text-gray-500 light text-small ml-1 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("and"))+" "+_vm._s(_vm.createTagsAccessArray(data.item.role_info) .length - 1)+" "+_vm._s(_vm.$t("more"))+" ")]):_vm._e()],1):_c('b-badge',{staticClass:"badge--blue badge--noclick"},[_vm._v(_vm._s(_vm.$t("all_tags")))])],1)]}},(_vm.$can('update', 'company_user'))?{key:"cell(role)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(data.item.role)+" ")])]}}:null,{key:"cell(actions)",fn:function(data){return [_c('div',[(
                            _vm.$can('update', 'invite') &&
                            !_vm.expiredDate(data.item.expires_at)
                        )?_c('b-button',{attrs:{"id":'edit_button' + data.item.id,"size":"sm","variant":"outline-primary"},on:{"click":function($event){return _vm.handleEditInvite(data.item)}}},[_c('i',{staticClass:"fas fa-pencil"})]):_vm._e(),_c('b-tooltip',{attrs:{"target":'edit_button' + data.item.id,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]),(_vm.$can('create', 'invite'))?_c('b-button',{attrs:{"id":'send_button' + data.item.id,"size":"sm","variant":"secondary"},on:{"click":function($event){return _vm.handleResendEmail(data.item)}}},[_c('i',{staticClass:"fas fa-envelope"})]):_vm._e(),_c('b-tooltip',{attrs:{"target":'send_button' + data.item.id,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t("send"))+" ")]),(
                            _vm.$can('update', 'invite') &&
                            !_vm.expiredDate(data.item.expires_at)
                        )?_c('b-button',{attrs:{"id":'delete_button' + data.item.id,"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.handleRevokeInvite(data.item)}}},[_c('i',{staticClass:"fas fa-close"})]):_vm._e(),_c('b-tooltip',{attrs:{"target":'delete_button' + data.item.id,"triggers":"hover"}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)]}}],null,true)})],1),_c('invite-form',{attrs:{"showModal":_vm.showInviteForm,"editInvite":_vm.editInvite},on:{"close":_vm.handleCloseInvite,"saved":_vm.handleCloseInvite}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }