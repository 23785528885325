// eslint-disable-no-v-html

<template>
    <b-list-group flush>
        <b-list-group-item
            style="cursor: pointer"
            class="px-0"
            v-for="d in activityData"
            :key="d.id"
            @click="handleRecentActivityClicked(d)"
        >
            <b-row align-v="center">
                <b-col cols="9">
                    <div class="d-flex align-items-center">
                        <b-avatar
                            variant="primary"
                            :text="getAvatarText(d)"
                            class="mr-3"
                        ></b-avatar>
                        <h4 class="mb-0 text-gray text-mini">
                            <span
                                v-html="
                                    expanded
                                        ? getDescriptionExpanded(d)
                                        : getDescription(d)
                                "
                            ></span>
                        </h4>
                    </div>
                </b-col>
                <b-col cols="3" class="text-mini text-gray text-right">
                    {{ createTimestamp(d) }}
                </b-col>
            </b-row>
        </b-list-group-item>
    </b-list-group>
</template>

<script>
import * as types from "@/store/types";
import { mapState } from "vuex";
export default {
    props: {
        activityData: {
            type: Array,
            required: true,
        },
        expanded: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapState({
            bus: (state) => state.data.businessUnits,
            sites: (state) => state.data.sites,
        }),
    },
    methods: {
        getAvatarText(d) {
            if (!d.first_name && !d.last_name) return "?"; // unknown

            const text = `${d.first_name[0]}${d.last_name[0]}`;

            return text;
        },

        getDescription(d) {
            const user = this.getUserName(d);
            const name = d.event_info_json.name;
            const value = d.event_info_json.value || "";
            const unit = d.event_info_json?.emission_type?.unit || "";
            const type = d.event_info_json?.emission_type?.type || "";
            const filename = d.event_info_json?.filename;
            const period = d.period_name;

            switch (d.event_type) {
                case "emission_source_created":
                    return `${user} ${this.$t("al_created_source")} ${name}`;
                case "emission_source_updated_name":
                    return `${user} ${this.$t(
                        "al_updated_source_name"
                    )} ${name}`;
                case "emission_source_updated_site":
                    return `${user} ${this.$t("al_updated_source_site")}`;
                case "emission_source_updated_bu":
                    return `${user} ${this.$t("al_updated_source_bu")}`;
                case "emission_source_deleted":
                    return `${user} ${this.$t("al_deleted_source")}`;
                case "emission_data_updated":
                    return `${user} ${this.$t("al_updated_value")}
                    ${this.$t("to")} ${value} ${unit} ${type}`;
                case "data_approved":
                    return `${user} ${this.$t("al_approved_data")}`;
                case "emission_data_notes_updated":
                    return `${user} ${this.$t("al_updated_notes")}`;
                case "attachment_uploaded":
                    return `${user} ${this.$t(
                        "al_uploaded_the_attachment"
                    )} ${filename}`;
                case "attachment_deleted":
                    return `${user} ${this.$t(
                        "al_deleted_the_attachment"
                    )} ${filename}`;
                case "tag_added":
                    return `${user} ${this.$t("al_added_the_tag")} ${
                        d.event_info_json
                    }`;
                case "tag_removed":
                    return `${user} ${this.$t("al_removed_the_tag")} ${
                        d.event_info_json
                    }`;
                case "period_locked":
                    return `${user} ${this.$t("al_locked_period")} ${period}`;
                case "period_unlocked":
                    return `${user} ${this.$t("al_unlocked_period")} ${period}`;
                case "metric_value_created":
                    return `${user} ${this.$t("al_created_cmv")}`;
                case "metric_deleted":
                    return `${user} ${this.$t("al_deleted_cm")} ${name}`;
                default:
                    return this.$t("al_unknown");
            }
        },
        getDescriptionExpanded(d) {
            const user = this.getUserName(d);
            const name = `<span class="bold">${d.emission_source_name}</span>`;
            const value = d.event_info_json.value;
            const unit = d.event_info_json?.emission_type?.unit || "";
            const type = d.event_info_json?.emission_type?.type || "";
            const bu = this.bus.find((b) => b.id === d.business_unit_id);
            const site = this.sites.find((s) => s.id === d.site_id);
            const period = d.period_name;
            const tag = `<span class="bold">${d.event_info_json}</span>`;
            const cm_name = `<span class="bold">${d.event_info_json?.name}</span>`;
            const cm_value = d.event_info_json.value;
            const cm_unit = d.event_info_json?.unit || "";
            const cm_domain = d.event_info_json?.description || "";

            switch (d.event_type) {
                case "emission_source_created":
                    return `${user} ${this.$t("al_created_new_es")} ${name}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}`;
                case "emission_source_updated_name":
                    return `${user} ${this.$t("al_updated_source_name")}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}
                        ${this.$t("to")} ${name}`;
                case "emission_source_updated_site":
                    return `${user} ${this.$t("al_updated_site")} ${name}
                        ${site ? `${this.$t("to")} ${site?.name}` : ""}`;
                case "emission_source_updated_bu":
                    return `${user} ${this.$t("al_updated_bu")}
                    ${name} ${this.$t("to")} ${bu?.name}`;
                case "emission_source_deleted":
                    return `${user} ${this.$t("al_deleted_source")} ${name}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}`;
                case "emission_data_updated":
                    return `${user} ${this.$t("al_updated_value")}
                    ${this.$t("for")} ${name}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}
                        ${this.$t("to")} ${value || 0}
                        ${unit} ${type} ${this.$t("for")} ${period}`;
                case "data_approved":
                    return `${user} ${this.$t("al_approved_data")}
                    ${this.$t("for")} ${name}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}`;
                case "emission_data_notes_updated":
                    return `${user} ${this.$t("al_updated_notes")}
                    ${this.$t("for")} ${name}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}`;
                case "attachment_uploaded":
                    return `${user} ${this.$t("al_uploaded_attachment")} ${name}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}`;
                case "attachment_deleted":
                    return `${user} ${this.$t("al_deleted_attachment")} ${name}
                        ${site ? `${this.$t("in")} ${site?.name}` : ""}`;
                case "tag_added":
                    return `${user} ${this.$t(
                        "al_added_the_tag"
                    )} ${tag} ${this.$t("to")} ${name}`;
                case "tag_removed":
                    return `${user} ${this.$t(
                        "al_removed_the_tag"
                    )} ${tag} ${this.$t("for")} ${name}`;
                case "period_locked":
                    return `${user} ${this.$t("al_locked_period")} ${period}`;
                case "period_unlocked":
                    return `${user} ${this.$t("al_unlocked_period")} ${period}`;
                case "metric_created":
                    return `${user} ${this.$t("al_created_new_cm")} ${cm_name}
                     ${
                         cm_unit
                             ? `${this.$t("al_created_cm_unit")} ${cm_unit}`
                             : ""
                     }
                        ${
                            cm_domain
                                ? `${this.$t(
                                      "al_created_cm_domain"
                                  )} ${cm_domain}`
                                : ""
                        }`;
                case "metric_updated":
                    return `${user}   ${
                        cm_unit
                            ? `${this.$t("al_updated_cm_unit")} ${cm_unit}`
                            : ""
                    }
                         ${
                             cm_name
                                 ? `${this.$t("al_updated_cm_name")} ${cm_name}`
                                 : ""
                         }`;

                case "metric_value_updated":
                    return `${user}
                        ${
                            cm_value
                                ? `${this.$t(
                                      "al_updated_cmv_value"
                                  )} ${cm_value}`
                                : ""
                        }
                        ${
                            cm_domain
                                ? `${this.$t("al_updated_cmv_description")} `
                                : ""
                        }`;
                case "metric_value_created":
                    return `${user} ${this.$t("al_created_cmv")}`;
                case "metric_deleted":
                    return `${user} ${this.$t("al_deleted_cm")} ${cm_name}`;
                default:
                    return this.$t("al_unknown");
            }
        },
        getUserName(d) {
            if (!d.first_name && !d.last_name) return this.$t("al_unknown"); // unknown
            return `${d.first_name} ${d.last_name}`;
        },
        createTimestamp(d) {
            return this.$formatTime(d.timestamp);
        },
        handleRecentActivityClicked(d) {
            // Only handle the click when the subject is emission_data or emission_source
            if (
                d.subject !== "emission_data" &&
                d.subject !== "emission_source"
            )
                return;

            // If we're talking about data, we need to set the period filter
            if (d.subject === "emission_data") {
                this.$store.dispatch(types.SET_FILTER, { period: d.period_id });
            }

            // Push the user to the data input page with query
            this.$router.push({
                name: "DataInput",
                query: {
                    source: d.emission_source_id,
                },
            });
        },
    },
};
</script>
