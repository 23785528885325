<template>
    <div
        @mousemove="handleMouseMove"
        @mouseleave="closeDropdown"
        @mouseenter="toggleDropdown"
        class="cc-dropdown"
    >
        <div class="d-flex align-items-center justify-content-between">
            <span>
                <i :class="icon" class="mr-2"></i>
                {{ title }}
            </span>
            <i class="fas fa-angle-right text-gray"></i>
        </div>
        <div
            v-if="isOpen"
            :class="'cc-dropdown-content--' + (isRight ? 'right' : 'left')"
            @mouseleave="closeDropdown"
        >
            <div
                v-for="item in items"
                :key="item.id"
                class="cc-dropdown-item"
                @click="selectItem(item)"
            >
                <i :class="item.icon" class="mr-2"></i>
                <span>{{ item.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CcDropdown",
    props: {
        items: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: false,
        },
        initialSelection: {
            type: Object,
            default: () => ({ label: "Select" }),
        },
    },
    data() {
        return {
            isOpen: false,
            active: null,
            isRight: false,
            nearRightEdge: 500,
        };
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectItem(item) {
            this.isOpen = false;
            this.$emit("selected", item);
        },
        closeDropdown() {
            this.isOpen = false;
        },
        handleMouseMove(event) {
            const windowWidth = window.innerWidth;
            this.isRight = event.clientX >= windowWidth - this.nearRightEdge;
        },
    },
    watch: {
        isRight(newValue) {
            return newValue;
        },
    },
};
</script>
