var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"ml-2"},[_c('p',{staticClass:"text-mini text-gray-500 m-0"},[_vm._v(_vm._s(_vm.$t("in_ton")))]),_c('h2',[_vm._v(_vm._s(_vm.$t("dashboard_ghg_header")))])])]},proxy:true}])},[_c('transition',{attrs:{"mode":"out-in","name":"slow-fade"}},[(!_vm.loading)?_c('D3HorizontalBar',{attrs:{"data":_vm.catData,"chartKey":"catData","labelKey":"name","idKey":"id","customStyle":{
                key: 'scope',
                styles: {
                    1: `fill: #5bc8ae;`,
                    2: `fill: #a6b4c6;`,
                    3: `fill: #d1d0ea;`,
                },
            },"barHeight":40,"barPadding":0.5},on:{"bar-clicked":function($event){return _vm.$emit('chart-clicked', $event)}}}):_c('b-skeleton-img')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }