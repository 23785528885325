<template>
    <div>
        <b-card class="cc-content cc-content-card">
            <b-row>
                <b-col>
                    <h1>{{ $t("Team") }}</h1>
                </b-col>
            </b-row>

            <div class="mt-6">
                <b-table
                    striped
                    hover
                    :fields="teamFields"
                    :items="team"
                    responsive
                >
                    <template #cell(name)="data">
                        {{ `${data.item.first_name} ${data.item.last_name}` }}
                    </template>

                    <template #cell(site_access)="data">
                        <div>
                            <div
                                v-if="
                                    !data.item.access_all_sites &&
                                    createSiteAccessArray(
                                        data.item.constraints?.site_ids
                                    ).length > 0
                                "
                            >
                                <b-badge
                                    class="badge--blue badge--noclick"
                                    :class="{
                                        'badge--red': createSiteAccessArray(
                                            data.item.constraints?.site_ids
                                        )[0].deleted,
                                        'badge--blue': !createSiteAccessArray(
                                            data.item.constraints?.site_ids
                                        )[0].deleted,
                                    }"
                                >
                                    {{
                                        createSiteAccessArray(
                                            data.item.constraints?.site_ids
                                        )[0].name
                                    }}
                                </b-badge>
                                <p
                                    v-if="
                                        createSiteAccessArray(
                                            data.item.constraints?.site_ids
                                        ).length > 1
                                    "
                                    class="text-gray-500 light text-small ml-1 mb-0"
                                >
                                    {{
                                        createSiteAccessArray(
                                            data.item.constraints?.site_ids
                                        ).length - 1
                                    }}
                                </p>
                            </div>

                            <b-badge class="badge--blue badge--noclick" v-else>
                                {{ $t("all_sites") }}
                            </b-badge>
                        </div>
                    </template>
                    <template #cell(business_access)="data">
                        <div>
                            <div
                                v-if="
                                    !data.item.access_all_business_units &&
                                    createBusinessAccessArray(
                                        data.item.constraints?.business_unit_ids
                                    ).length > 0
                                "
                            >
                                <b-badge class="badge--blue badge--noclick">
                                    {{
                                        createBusinessAccessArray(
                                            data.item.constraints
                                                ?.business_unit_ids
                                        )[0]?.name || $t("al_unknown")
                                    }}
                                </b-badge>
                                <p
                                    v-if="
                                        createBusinessAccessArray(
                                            data.item.constraints
                                                ?.business_unit_ids
                                        ).length > 1
                                    "
                                    class="text-gray-500 light text-small ml-1 mb-0"
                                >
                                    {{ $t("and") }}
                                    {{
                                        createBusinessAccessArray(
                                            data.item.constraints
                                                ?.business_unit_ids
                                        ).length - 1
                                    }}
                                    {{ $t("more") }}
                                </p>
                            </div>

                            <b-badge
                                class="badge--blue badge--noclick"
                                v-else
                                >{{ $t("all_business_units") }}</b-badge
                            >
                        </div>
                    </template>
                    <template #cell(tag_access)="data">
                        <div>
                            <div
                                v-if="
                                    !data.item.access_all_tags &&
                                    createTagsAccessArray(
                                        data.item.constraints?.tag_ids
                                    ).length > 0
                                "
                            >
                                <b-badge class="badge--blue badge--noclick">
                                    {{
                                        createTagsAccessArray(
                                            data.item.constraints?.tag_ids
                                        )[0].title
                                    }}
                                </b-badge>
                                <p
                                    v-if="
                                        createTagsAccessArray(
                                            data.item.constraints?.tag_ids
                                        ).length > 1
                                    "
                                    class="text-gray-500 light text-small ml-1 mb-0"
                                >
                                    {{ $t("and") }}
                                    {{
                                        createTagsAccessArray(
                                            data.item.constraints?.tag_ids
                                        ).length - 1
                                    }}
                                    {{ $t("more") }}
                                </p>
                            </div>

                            <b-badge
                                class="badge--blue badge--noclick"
                                v-else
                                >{{ $t("all_tags") }}</b-badge
                            >
                        </div>
                    </template>
                    <template
                        #cell(role)="data"
                        v-if="$can('update', 'company_user')"
                    >
                        <div
                            class="d-flex justify-content-start align-items-center"
                            style="text-transform: capitalize"
                        >
                            {{ $t(`form_${data.item.role.toLowerCase()}`) }}
                        </div>
                    </template>
                    <template #cell(actions)="data">
                        <b-button
                            :id="'edit_button' + data.item.id"
                            variant="outline-primary"
                            size="sm"
                            @click="handleEditUser(data.item)"
                            v-if="$can('update', 'company_user')"
                            class="ml-2"
                        >
                            <i class="fas fa-pencil"></i>
                        </b-button>
                        <b-tooltip
                            :target="'edit_button' + data.item.id"
                            triggers="hover"
                        >
                            {{ $t("edit") }}
                        </b-tooltip>
                        <b-button
                            :id="'delete_button' + data.item.id"
                            variant="outline-danger"
                            size="sm"
                            @click="handleRemove(data.item)"
                            v-if="
                                $can('delete', 'company_user') &&
                                data.item.role !== 'admin'
                            "
                            class="ml-2"
                        >
                            <i class="fas fa-trash"></i>
                        </b-button>
                        <b-tooltip
                            :target="'delete_button' + data.item.id"
                            triggers="hover"
                        >
                            {{ $t("delete") }}
                        </b-tooltip>
                    </template>
                </b-table>
            </div>
        </b-card>

        <Invites />

        <team-edit-modal
            :showModal="showUserForm"
            :editUser="editUser"
            @close="showUserForm = false"
            @saved="handleSaved"
        ></team-edit-modal>
    </div>
</template>

<script>
import Invites from "./Invites.vue";
import TeamEditModal from "./TeamEditModal.vue";

import companyApi from "@/api/company";
import swal from "sweetalert2";

export default {
    name: "CCTeam",
    components: {
        TeamEditModal,
        Invites,
    },
    data() {
        return {
            team: [],
            teamFields: [
                { key: "name", label: this.$t("label_name") },
                { key: "role", label: this.$t("role") },
                { key: "site_access", label: this.$t("label_site_access") },
                {
                    key: "business_access",
                    label: this.$t("label_business_unit_access"),
                },
                { key: "tag_access", label: this.$t("label_tag_access") },
                { key: "actions", label: this.$t("label_actions") },
            ],

            showUserForm: false,
            editUser: {},
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getTeam();
        },
        async getTeam() {
            try {
                const response = await companyApi.getTeam();
                this.team = response.data.filter(
                    (user) => !this.excludeRole(user.role)
                );
            } catch (error) {
                console.log(error);
            }
        },
        async deleteUser(user) {
            try {
                await companyApi.deleteUser(user);
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        handleEditUser(user) {
            this.editUser = user;
            this.showUserForm = true;
        },
        handleSaved() {
            this.init();
            this.showUserForm = false;
            this.showUserForm = false;
        },
        async handleRemove(item) {
            const result = await swal.fire({
                title: this.$t("team_handleRemove_title"),
                text: this.$t("team_handleRemove_text", {
                    first_name: item.first_name,
                    last_name: item.last_name,
                }),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.value) this.deleteUser(item);
        },
        createSiteAccessArray(sites) {
            if (!sites) return [];
            const res = sites.map((x) => {
                return (
                    this.$store.getters.getSite(x) ||
                    this.$store.getters.getDeletedSite(x) || {
                        name: `Unknown (${x})`,
                    }
                );
            });

            return res;
        },
        createBusinessAccessArray(businesses) {
            if (!businesses) return [];
            const res = businesses.map((x) => {
                return this.$store.getters.getBusinessUnit(x);
            });

            return res;
        },
        createTagsAccessArray(tags) {
            if (!tags) return [];
            const res = tags.map((x) => {
                return this.$store.getters.getTag(x);
            });

            return res;
        },
        excludeRole(role) {
            const rolesToHide = ["support"];
            return rolesToHide.includes(role);
        },
    },
};
</script>
