<template>
    <KeepAlive>
        <div class="controls__sticky-bar">
            <div>
                <slot name="title"></slot>
            </div>
            <div class="d-flex flex-column" id="control-buttons">
                <!-- MAIN CONTROL BUTTONS -->
                <div class="d-flex justify-content-end align-items-center">
                    <Filters
                        ref="filters"
                        class="mr-4"
                        v-if="!hide.includes('filters')"
                        :hideFilters="hideFilters"
                        @opened="controlOpened"
                    />
                    <Sort
                        ref="sort"
                        v-if="!hide.includes('sort')"
                        :sortOptions="sortOptions"
                        @opened="controlOpened"
                    />
                    <slot></slot>
                    <Periods
                        id="period_button"
                        class="ml-3"
                        ref="periods"
                        highlight
                        @opened="controlOpened"
                        v-if="!hideFilters.includes('period')"
                    />
                    <b-tooltip target="period_button" triggers="hover">
                        {{ $t("select_period") }}
                    </b-tooltip>
                    <!-- <PeriodSelector /> -->
                </div>

                <!-- BADGES -->
                <div
                    class="d-flex mt-4 mb-2 justify-content-end"
                    v-if="!hideBadges"
                >
                    <SitesBadges
                        :filters="filters"
                        v-if="!hide.includes('filters')"
                    />
                    <BusinessUnitsBadges
                        :filters="filters"
                        v-if="!hide.includes('filters')"
                    />
                    <ScopesBadges
                        :filters="filters"
                        v-if="!hide.includes('filters')"
                    />
                    <TagsBadges
                        :filters="filters"
                        v-if="!hide.includes('filters')"
                    />
                    <transition-group name="badge-animation" tag="div">
                        <div
                            key="validation"
                            class="badge badge--blue mr-2 badge--close badge-animation-item"
                            v-if="
                                validation !== null &&
                                !hide.includes('validation')
                            "
                            @click="deleteFilter('validation')"
                        >
                            {{ validation }}
                        </div>
                        <div
                            key="category"
                            class="badge badge--blue text-capitalize badge--close badge-animation-item"
                            v-if="
                                category !== null && !hide.includes('category')
                            "
                            @click="deleteFilter('category')"
                        >
                            {{ $t(category.name) }}
                        </div>
                        <div
                            key="search"
                            class="badge badge--blue text-capitalize badge--close badge-animation-item"
                            v-if="filters.search"
                            @click="deleteFilter('search')"
                        >
                            <i class="fas fa-search mr-1"></i
                            >{{ filters.search }}
                        </div>
                    </transition-group>
                </div>
            </div>
        </div>
    </KeepAlive>
</template>

<script>
import Filters from "./filters/Filters.vue";
import Periods from "./periods/Periods.vue";
import Sort from "./sort/Sort.vue";

import BusinessUnitsBadges from "./badges/BusinessUnitBadges.vue";
import ScopesBadges from "./badges/ScopesBadges.vue";
import SitesBadges from "./badges/SitesBadges.vue";
import TagsBadges from "./badges/TagsBadges.vue";

import * as types from "@/store/types";
import { mapState } from "vuex";

export default {
    components: {
        Filters,
        Sort,
        Periods,
        SitesBadges,
        BusinessUnitsBadges,
        ScopesBadges,
        TagsBadges,
    },
    props: {
        hide: {
            type: Array,
            required: false,
            default: () => [],
        },
        hideFilters: {
            type: Array,
            required: false,
            default: () => [],
        },
        hideBadges: {
            type: Boolean,
            required: false,
            default: false,
        },
        sortOptions: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    computed: {
        ...mapState({
            filters: (state) => state.filters,
        }),
        category() {
            const cat = this.$store.getters.findDataPoint(
                "categories",
                this.filters.category
            );
            if (!cat) return null;
            return cat;
        },
        validation() {
            if (this.filters.validation === null) return null;
            switch (+this.filters.validation) {
                case 0:
                    return this.$t("not_validated");
                case 1:
                    return this.$t("validated");
            }
        },
    },
    created() {
        document.addEventListener("click", this.createCloseListener);
    },
    methods: {
        createCloseListener(e) {
            if (!e) return;
            const node = document.querySelector("#control-buttons");
            if (!node.contains(e.target)) this.controlOpened("");
        },
        deleteFilter(filter) {
            this.$store.dispatch(types.SET_FILTER, { [filter]: null });
        },
        controlOpened(e) {
            const buttons = ["filters", "sort", "periods"];
            buttons
                .filter((x) => x !== e)
                .forEach((x) => {
                    if (!this.$refs[x]) return;
                    this.$refs[x].closeMenu();
                });
        },
    },
    destroyed() {
        document.removeEventListener("click", this.createCloseListener);
    },
};
</script>
