<template>
    <div v-if="metricValue">
        <span class="custom-metric-sidebar__content-header mb-4">
            <Periods small ref="periods" highlight active id="period_badge" />
            <b-tooltip target="period_badge" triggers="hover">
                {{ $t("select_period") }}
            </b-tooltip>
            <b-badge class="mx-4" variant="primary" style="color: black">
                {{ customMetric?.name }}
            </b-badge>
        </span>
        <metric-value-data-input
            :metricValue="metricValue"
            :unitDetails="customMetric.unit"
            @input-update="handleCustomMetricValueInputUpdate"
            @refresh="$emit('refresh')"
            @close="$emit('close')"
        >
            <template v-slot:attachments>
                <slot name="attachments"></slot>
            </template>
        </metric-value-data-input>
    </div>
</template>

<script>
import Periods from "@/components/controls/periods/Periods";
import MetricValueDataInput from "./MetricValueDataInput.vue";

export default {
    components: {
        Periods,
        MetricValueDataInput,
    },
    props: {
        metricValue: {
            type: Object,
            required: false,
            default: null,
        },
        customMetric: {
            type: Object,
            required: true,
            default: null,
        },
    },
    methods: {
        handleCustomMetricValueInputUpdate(value) {
            console.log(value);
        },
    },
    watch: {
        metricValue: {
            handler(value) {
                this.$emit("input-update", value);
            },
            deep: true,
        },
    },
};
</script>
