<template>
    <ControlBar
        :hide="['filters', 'category']"
        :hideFilters="['category', 'scopes', 'tags', 'validation']"
        :sortOptions="sortOptions"
        class="mb-5"
    >
        <div class="d-flex justify-content-end align-items-center ml-4">
            <a
                href="#"
                class="text-gray-500 light"
                @click.prevent="$emit('expand-all')"
                v-if="expandable"
            >
                <i class="fas fa-angle-double-down"></i>
                {{ $t("expand") }}
            </a>
            <a
                href="#"
                class="text-gray-500 light"
                @click.prevent="$emit('collapse-all')"
                v-else
            >
                <i class="fas fa-angle-double-up"></i>
                {{ $t("collapse") }}
            </a>
        </div>
    </ControlBar>
</template>
<script>
import ControlBar from "@/components/controls/ControlBar.vue";

export default {
    name: "MetricHeader",
    components: {
        ControlBar,
    },
    props: {
        expandable: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            sortOptions: [
                { text: this.$t("sort_by_site"), value: "site" },
                { text: this.$t("sort_by_bu"), value: "bu" },
                { text: this.$t("sort_by_value"), value: "value" },
                {
                    text: this.$t("sort_by_comments_az"),
                    value: "descriptionAZ",
                },
                {
                    text: this.$t("sort_by_comments_za"),
                    value: "descriptionZA",
                },
            ],
            expandAll: false,
            collapseAll: false,
        };
    },
};
</script>
