<template>
    <transition-group name="badge-animation" tag="div">
        <div
            class="badge badge--blue mr-2 badge--close badge-animation-item"
            v-for="site in sites"
            :key="site.id"
            @click="deleteFilter(site.id)"
        >
            {{ site.name }}
        </div>
    </transition-group>
</template>

<script>
import * as types from "@/store/types";

export default {
    props: {
        filters: {
            type: Object,
            required: true,
        },
    },
    computed: {
        sites() {
            const sites = [];

            // Get other site objects from store
            this.filters.sites.forEach((site) => {
                // Handle unassigned case
                if (site === -1) {
                    sites.push({ id: -1, name: "Unassigned site" });
                    return;
                }
                const currentSite = this.$store.getters.findDataPoint(
                    "sites",
                    site
                );
                sites.push(currentSite);
            });

            return sites;
        },
    },
    methods: {
        deleteFilter(id) {
            // Find the filter index
            const siteIndex = this.filters.sites.findIndex((x) => x === id);

            // And remove the site
            const selectedSites = [
                ...this.filters.sites.slice(0, siteIndex),
                ...this.filters.sites.slice(siteIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                sites: selectedSites,
            });
        },
    },
};
</script>
