import Vue from "vue";

import CcInput from "@/components/elements/form_elements/CcInput";
import CcInputTel from "@/components/elements/form_elements/CcInputTel";
import CcSelect from "@/components/elements/form_elements/CcSelect";
import CcSelectIcon from "@/components/elements/form_elements/CcSelectIcon.vue";
import CcDropdown from "@/components/elements/form_elements/CcDropdown";

Vue.component("CcSelect", CcSelect);
Vue.component("CcInput", CcInput);
Vue.component("CcInputTel", CcInputTel);
Vue.component("CcSelectIcon", CcSelectIcon);
Vue.component("CcDropdown", CcDropdown);
