<template>
    <div>
        <b-sidebar
            v-model="sidebarVisible"
            bg-variant="white"
            width="600px"
            right
            backdrop
            shadow
        >
            <template #header>
                <MetricSidebarHeader
                    :title="title"
                    @open-modal="openModal"
                    @handle-cancel="handleCancel"
                />
            </template>

            <!-- Sidebar content -->
            <div class="p-4">
                <metric-data-input
                    v-if="!selectedCustomMetricValue"
                    :custom-metric="metric"
                    @input="handleCustomMetricInput"
                    @close="handleCancel"
                    @refresh="handleRefresh"
                    :modal="false"
                />
                <MetricValueForm
                    v-else
                    :customMetric="metric"
                    :metricValue="selectedCustomMetricValue"
                    @refresh="handleRefresh"
                />
            </div>
            <!-- Sidebar footer -->
            <template #footer>
                <!-- Metric values get autosaved, so this is only for a new metric -->
                <MetricSidebarButtons
                    v-if="metric && !selectedCustomMetricValue"
                    :selectedCustomMetric="selectedCustomMetric"
                    @cancel="handleCancel"
                />

                <MetricSidebarNavigation
                    v-if="selectedCustomMetricValue"
                    :selectedCustomMetricValue="selectedCustomMetricValue"
                    @navigate="handleNavigate"
                    @refresh="handleRefresh"
                    class="mb-1 px-4"
                />
            </template>
        </b-sidebar>

        <!-- MODAL -->
        <b-modal id="modal" size="xl" hide-footer>
            <template #modal-header>
                <div class="custom-metric-sidebar__header px-2">
                    <h2 class="mb-0">{{ title }}</h2>
                    <div>
                        <b-button
                            id="minimize_button"
                            variant="link"
                            @click="minimizeModal"
                        >
                            <i
                                class="fa-solid fa-down-left-and-up-right-to-center h2"
                                style="color: #a4a3a3c3"
                            ></i>
                        </b-button>
                        <b-tooltip target="minimize_button" triggers="hover">
                            {{ $t("minimize") }}
                        </b-tooltip>
                        <b-button-close
                            @click="closeModal"
                            style="font-size: 25px"
                        ></b-button-close>
                    </div>
                </div>
            </template>
            <!-- Modal content -->
            <metric-data-input
                v-if="!selectedCustomMetricValue"
                :custom-metric="metric"
                @input="handleCustomMetricInput"
                @close="handleCancel"
                :modal="true"
            />
            <MetricValueForm
                v-else
                :customMetric="metric"
                :metricValue="selectedCustomMetricValue"
                @refresh="handleRefresh"
            />

            <!-- Metric values get autosaved, so this is only for a new metric -->
            <MetricSidebarButtons
                v-if="metric && !selectedCustomMetricValue"
                :selectedCustomMetric="selectedCustomMetric"
                @cancel="handleCancel"
            />

            <!-- Modal footer -->
            <template #modal-footer>
                <div class="py-3 w-100">
                    <MetricSidebarNavigation
                        v-if="selectedCustomMetricValue"
                        :selectedCustomMetricValue="selectedCustomMetricValue"
                        @navigate="handleNavigate"
                        @refresh="handleRefresh"
                    />
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import ModalMixin from "@/mixins/custom_metrics/ModalMixin";
import MetricSidebarNavigation from "./MetricSidebarNavigation.vue";
import MetricSidebarHeader from "./MetricSidebarHeader.vue";
import MetricSidebarButtons from "./MetricSidebarButtons.vue";

import MetricDataInput from "./data-input/MetricDataInput.vue";

import MetricValueForm from "./data-input/MetricValueForm.vue";

const newMetric = {
    name: "",
    unit: "",
    global_site: true,
    global_business_unit: true,
    description: "",
    siteIds: [],
    businessUnitIds: [],
};

export default {
    name: "MetricSidebar",
    mixins: [ModalMixin],
    components: {
        MetricSidebarNavigation,
        MetricSidebarHeader,
        MetricDataInput,
        MetricValueForm,
        MetricSidebarButtons,
    },
    props: {
        selectedCustomMetric: {
            type: Object,
            required: false,
            default: null,
        },
        selectedCustomMetricValue: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            metric: null,
            loading: false,
            sidebarVisible: false,
        };
    },
    computed: {
        title() {
            return this.selectedCustomMetric?.id
                ? this.$t("edit_metric")
                : this.$t("create_new_metric");
        },
    },
    methods: {
        // SIDEBAR METHODS
        open() {
            this.sidebarVisible = true;
        },
        toggle() {
            this.sidebarVisible = !this.sidebarVisible;
        },
        close() {
            this.sidebarVisible = false;
            this.$emit("refresh");
        },
        handleCancel() {
            this.close();
            this.closeModal();
            this.metric = structuredClone(newMetric);
            this.metricValue = null;
        },
        handleCustomMetricInput(value) {
            this.metric = value;
        },
        handleNavigate(value) {
            this.$emit("navigate-metric-values", value);
        },
        handleRefresh() {
            this.$emit("refresh");
        },
    },
    watch: {
        selectedCustomMetric: {
            handler(value) {
                if (value) {
                    this.metric = structuredClone(value);
                } else {
                    this.metric = structuredClone(newMetric);
                }
            },
            immediate: true,
        },
    },
};
</script>
