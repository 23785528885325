var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative h-100"},[_c('div',{staticClass:"menu-button",class:_vm.opened ? 'menu-button--active' : '',on:{"click":_vm.toggleMenu}},[_c('i',{staticClass:"fa-solid fa-filter mr-2"}),_vm._v(" "+_vm._s(_vm.$t("filter"))+" "),(_vm.badgeText > 0)?_c('b-badge',{staticClass:"badge--blue ml-1"},[_vm._v(_vm._s(_vm.badgeText))]):_vm._e()],1),_c('div',{staticClass:"menu-select__wrapper",class:_vm.opened ? 'menu-select__wrapper--visible' : '',style:(`transform: translateX(-${_vm.shiftLeft}px);`)},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"menu-items__wrapper",class:_vm.opened ? 'menu-items__wrapper--visible' : '',attrs:{"id":"filter-select"}},[_c('search-filters',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideFilters.includes('search')),expression:"!hideFilters.includes('search')"}],staticClass:"menu-select__item--no-hover",attrs:{"opened":_vm.opened}}),_vm._l((_vm.availableFilters),function(filter){return _c('div',{key:filter,staticClass:"menu-select__item",class:_vm.selected === filter
                            ? 'menu-select__item--selected'
                            : '',on:{"click":function($event){return _vm.selectFilter(filter)}}},[_c('span',[_c('i',{staticClass:"fas mr-3",class:_vm.generateFilterIcon(filter)}),_vm._v(_vm._s(_vm.generateFilterString(filter))+" "),(
                                _vm.filters[filter] !== null &&
                                _vm.filters[filter].length > 0
                            )?_c('i',{staticClass:"fa-solid fa-circle menu-select__indicator"}):_vm._e()]),_c('i',{staticClass:"fa-solid fa-chevron-right"})])}),_c('div',{staticClass:"menu-select__sidebar",class:_vm.selected !== ''
                            ? 'menu-select__sidebar--visible'
                            : '',style:(`top: ${_vm.menuPosition}px;`),attrs:{"id":"filter-sidebar"}},[_c('SiteFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === 'sites'),expression:"selected === 'sites'"}]}),_c('BuFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === 'businessUnits'),expression:"selected === 'businessUnits'"}]}),_c('ScopeFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === 'scopes'),expression:"selected === 'scopes'"}]}),_c('CategoryFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === 'category'),expression:"selected === 'category'"}],on:{"selected":_vm.toggleMenu}}),_c('TagFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === 'tags'),expression:"selected === 'tags'"}]}),_c('ValidatedFilters',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected === 'validation'),expression:"selected === 'validation'"}],on:{"selected":_vm.toggleMenu}})],1),_c('div',{staticClass:"menu-select__clear",on:{"click":_vm.clearFilters}},[_c('i',{staticClass:"fas fa-xmark mr-3"}),_vm._v(" "+_vm._s(_vm.$t("filters_clear"))+" ")])],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }