<template>
    <b-navbar
        container-classes="container-fluid"
        class="navbar-top navbar-expand cc-header content-padding"
    >
        <h1 class="m-0 header-h1">
            <i v-if="icon !== ''" class="mr-3" :class="icon"></i>
            <span @click="$emit('click:title')">{{ title }}</span>
            <span class="light" v-if="showYear">
                <i class="fal fa-chevron-right text-mini ml-2 mr-3"></i>
                {{ period.name }}
            </span>
            <slot name="subtitle">
                <span class="light text-gray-500">{{ subtitle }}</span>
            </slot>
        </h1>
        <div class="filters ml-6">
            <slot name="filters"></slot>
        </div>
        <UserNavigation />
    </b-navbar>
</template>

<script>
import UserNavigation from "@/components/navigation/UserNavigation";

export default {
    components: { UserNavigation },
    props: {
        title: {
            type: String,
            required: false,
            default: "",
            description: "A title for the current page.",
        },
        subtitle: {
            type: String,
            required: false,
            default: "",
        },
        icon: {
            type: String,
            required: false,
            default: "",
            description: "Icon for the page.",
        },
        showYear: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        period() {
            return this.$store.getters.getCurrentPeriod();
        },
    },
};
</script>
