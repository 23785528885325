import Vue from "vue";
import App from "./App.vue";
import router from "./router";

/* We're using the Argon Dashboard as base template
   The source files can be found in plugins/argon
   Documentation: https://www.creative-tim.com/learning-lab/bootstrap-vue/overview/argon-dashboard
*/
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DashboardPlugin from "./plugins/argon/dashboard-plugin";
// Import our CSS AFTER the template CSS because we know better.
import "./assets/styles/main.scss";
// Import the Auth plugin here
import { Auth0Plugin } from "./auth";

// Import utils here
import decimals from "./util/decimals";
import formatTime from "./util/time";

// Import global components
import "./util/global";

// Import the Vuex store
import store from "./store/index.js";
// Add amplitude
import amplitude from "./util/amplitude";
// Add Vitally
import vitally from "./util/vitally";
// Add GA
import VueGtag from "vue-gtag";
// WYSIWYG
import Vue2Editor from "vue2-editor";
// Permissions checker
import can from "./util/permissions";
// Feature checker
import has_feature from "./util/features";
// Translations
import { i18n } from "./util/i18n";
// Sentry
import * as Sentry from "@sentry/vue";

Vue.component("multiSelect", Multiselect);

// Import the Auth0 configuration
const domain = process.env.VUE_APP_auth0_domain;
const clientId = process.env.VUE_APP_auth0_client_id;
const audience = process.env.VUE_APP_auth0_audience;

document.title = "Futureproofed";

// Make Argon Dashboard globally available
Vue.use(DashboardPlugin);

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,
    onRedirectCallback: (appState: any) => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    },
});

Vue.use(amplitude);

Vue.use(vitally);

Vue.use(
    VueGtag,
    {
        config: { id: "G-YYEL8NK9DQ" },
    },
    router
);

Vue.use(Vue2Editor);

Vue.config.productionTip = false;

// Add custom utils to the Vue prototype
Vue.use(decimals);
Vue.use(formatTime);

Vue.use(can);

Vue.use(has_feature);

if (process.env.NODE_ENV === "production") {
    // Configure Sentry
    Sentry.init({
        Vue,
        dsn: "https://ef5451197ed34d478a1bcc8471512691@o39657.ingest.us.sentry.io/5964049",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        // Not using tracing, just error logging for now
        tracesSampleRate: 0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    });
}

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
