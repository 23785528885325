<template>
    <div>
        <b-form-input
            type="number"
            :value="localValueThreshold"
            @input="handleThresholdInput"
            min="1"
            step="1"
        />
        <b-form-input
            type="range"
            :value="localValueThreshold"
            @input="handleThresholdInput"
            min="1"
            max="100"
            step="1"
        />
        <Transition mode="out-in" name="slide-down">
            <p class="text-xs text-danger" v-if="localValueThreshold < 1">
                {{ $t("input_value_threshold_error") }}
            </p>
        </Transition>
    </div>
</template>

<script>
export default {
    props: {
        valueThresholdObject: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            localValueThreshold: 1,
            updateTimeout: null,
        };
    },
    methods: {
        handleThresholdInput(value) {
            this.localValueThreshold = value;
            if (value < 1) return;
            clearTimeout(this.updateTimeout);
            this.updateTimeout = setTimeout(() => {
                this.$emit("update:valueTreshold", value);
            }, 300);
        },
    },
    watch: {
        valueThresholdObject: {
            handler(value) {
                if (!value) return;
                this.localValueThreshold = value.configuration_value;
            },
            immediate: true,
        },
    },
};
</script>
