import amplitude from "amplitude-js";

export default {
    install: (Vue) => {
        amplitude.getInstance().init("07de7454ab2fcf4da05743a650d1880e");

        // eslint-disable-next-line
        Vue.prototype.$amplitude = amplitude.getInstance();
    },
};
