<template>
    <td :class="sort === 'site' ? 'cc-table__column--highlight' : ''">
        <span
            class="badge badge-pill"
            :class="
                metricValue.site_id === null ? 'badge--empty' : 'badge--blue'
            "
        >
            <span>{{ getSitesBadgeText(metricValue) }} </span>
        </span>
    </td>
</template>

<script>
import BadgeTextMixin from "@/mixins/custom_metrics/BadgeTextMixin";
export default {
    mixins: [BadgeTextMixin],
    props: {
        metricValue: {
            type: Object,
            required: true,
        },
        sites: {
            type: Array,
            required: true,
        },
        sort: {
            type: String,
            required: false,
        },
    },
    methods: {
        getSiteName(id) {
            if (!id) return "";
            const site = this.sites.find((x) => x.id === id);

            if (site) return site.name;
            else return this.$t("badge_no_site");
        },
    },
};
</script>
