<template>
    <div class="custom-metric__form-footer">
        <b-button variant="outline-dark" @click="$emit('cancel')">{{
            $t("cancel")
        }}</b-button>
        <b-button variant="primary" type="submit" form="input-form">
            {{
                selectedCustomMetric?.id ? $t("update") : $t("cm_create_button")
            }}
        </b-button>
    </div>
</template>

<script>
export default {
    name: "MetricSidebarButtons",
    props: {
        selectedCustomMetric: {
            type: Object,
            required: false,
            default: null,
        },
    },
};
</script>
