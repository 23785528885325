<template>
    <div v-if="customMetric">
        <b-form id="input-form" @submit.prevent="handleFormSubmit">
            <div :class="modal ? 'custom-metric__modal-item-container' : ''">
                <div :class="modal ? 'custom-metric__modal-item' : ''">
                    <!-- TITLE -->
                    <label for="metric_name">
                        {{ $t("label_metric_name") }}
                        <span class="text-danger ml-2">*</span>
                    </label>
                    <b-form-group
                        :class="modal ? 'container p-0 pr-1' : ''"
                        id="custom-metric-name"
                        :invalid-feedback="$t('enter_metric_name')"
                    >
                        <b-form-input
                            id="metric_name"
                            type="text"
                            :placeholder="$t('enter_metric_name')"
                            v-model="localCustomMetric.name"
                            :state="validate ? nameState : ''"
                            required
                            trim
                        ></b-form-input>
                    </b-form-group>
                </div>

                <!-- UNIT -->
                <div :class="modal ? 'custom-metric__modal-item' : ''">
                    <label for="metric-unit">
                        {{ $t("label_unit") }}
                        <span class="text-danger ml-2">*</span>
                    </label>
                    <b-form-group
                        :class="modal ? 'container p-0 pl-1' : ''"
                        id="custom-metric-unit"
                        :invalid-feedback="$t('enter_metric_unit')"
                        trim
                    >
                        <b-form-input
                            id="metric-unit"
                            v-model="localCustomMetric.unit"
                            :placeholder="$t('metric_unit_placeholder')"
                            :state="validate ? unitState : ''"
                            required
                        ></b-form-input>
                        <b-form-text>{{ $t("metric_unit_text") }}</b-form-text>
                    </b-form-group>
                </div>
            </div>
            <b-alert show class="mt-3" v-if="localCustomMetric.unit">
                <i class="fas fa-info-circle"></i>
                {{ $t("metric_unit_alert") }} {{ localCustomMetric.unit }}.
            </b-alert>

            <!-- DESCRIPTION -->
            <div :class="modal ? 'custom-metric__modal-item' : ''">
                <label for="metric_description"
                    >{{ $t("label_description") }}
                </label>
                <b-form-group
                    :class="modal ? 'container p-0 pr-1' : ''"
                    id="custom-metric-description"
                    :invalid-feedback="$t('cm_enter_description')"
                >
                    <b-form-textarea
                        id="metric_description"
                        type="text"
                        :placeholder="$t('cm_enter_description')"
                        v-model="localCustomMetric.description"
                        rows="5"
                        trim
                    ></b-form-textarea>
                </b-form-group>
            </div>

            <MetricsDomainSelector
                ref="domainSelector"
                :custom-metric="localCustomMetric"
                :validate="validate"
                @input:metric="handleMetricInput"
            />
        </b-form>
    </div>
</template>

<script>
import MetricsDomainSelector from "./MetricDomainSelector.vue";

import customMetricsApi from "@/api/custom_metrics";

import * as types from "@/store/types";

export default {
    name: "MetricDataInput",
    components: {
        MetricsDomainSelector,
    },
    props: {
        customMetric: {
            type: Object,
            required: false,
        },
        modal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            domainState: null,
            validate: false,
        };
    },
    computed: {
        localCustomMetric: {
            get() {
                return {
                    ...this.customMetric,
                    siteIds: Array.isArray(this.customMetric.siteIds)
                        ? this.customMetric.siteIds
                        : this.customMetric.siteIds
                              ?.split(",")
                              .map((x) => parseInt(x)),
                    businessUnitIds: Array.isArray(
                        this.customMetric.businessUnitIds
                    )
                        ? this.customMetric.businessUnitIds
                        : this.customMetric.businessUnitIds
                              ?.split(",")
                              .map((x) => parseInt(x)),
                };
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        nameState() {
            return this.localCustomMetric?.name?.length >= 1;
        },
        unitState() {
            return this.localCustomMetric?.unit?.length >= 1;
        },
    },
    methods: {
        async handleFormSubmit() {
            this.validate = true;

            // Check for selected bu/site (multiselect component doesn't halt submit)
            if (
                this.localCustomMetric.global_business_unit === false &&
                this.localCustomMetric.businessUnitIds.length === 0
            )
                return;
            if (
                this.localCustomMetric.global_site === false &&
                this.localCustomMetric.siteIds.length === 0
            )
                return;

            // Parse data
            const customMetric = {
                ...this.localCustomMetric,
                site_limitation_ids: this.localCustomMetric.siteIds?.toString(),
                business_unit_limitation_ids:
                    this.localCustomMetric.businessUnitIds?.toString(),
            };
            if (this.localCustomMetric.id) await this.editMetric(customMetric);
            else await this.createNewMetric(customMetric);

            this.$emit("close");
            this.$emit("refresh");

            this.validate = false;
        },
        async createNewMetric(customMetric) {
            try {
                const result = await customMetricsApi.add(customMetric);
                this.$store.dispatch(types.SET_TOAST, result.message);
            } catch (error) {
                console.log(error);
            }
        },
        async editMetric(customMetric) {
            try {
                await customMetricsApi.update(
                    this.customMetric.id,
                    customMetric
                );
                this.$store.dispatch(types.SET_TOAST, this.$t("cm_updated"));
            } catch (error) {
                console.log(error);
            }
        },
        handleMetricInput(input) {
            this.localCustomMetric = {
                ...this.localCustomMetric,
                ...input,
            };
        },
    },
};
</script>
