var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-team-user","title":`${_vm.user.first_name} ${_vm.user.last_name} - ${_vm.user.email}`,"ok-title":_vm.$t('save'),"size":"md","visible":_vm.showModal},on:{"ok":_vm.handleSave,"close":function($event){return _vm.$emit('close')},"cancel":function($event){return _vm.$emit('close')},"hidden":function($event){return _vm.$emit('close')}}},[(_vm.user)?_c('div',[_c('b-form',[_c('sites-multiselect',{attrs:{"userSelected":_vm.user?.constraints?.site_ids || [],"userAll":!!_vm.user.access_all_sites},on:{"update":({ selected, all }) => {
                        this.selectSites = selected;
                        this.allSites = all;
                    }}}),_c('business-units-multiselect',{attrs:{"userSelected":_vm.user?.constraints?.business_unit_ids || [],"userAll":!!_vm.user.access_all_business_units},on:{"update":({ selected, all }) => {
                        this.selectBusinessUnits = selected;
                        this.allBusinessUnits = all;
                    }}}),_c('tags-multiselect',{attrs:{"userSelected":_vm.user?.constraints?.tag_ids || [],"userAll":!!_vm.user.access_all_tags},on:{"update":({ selected, all }) => {
                        this.selectTags = selected;
                        this.allTags = all;
                    }}}),_c('b-form-group',{attrs:{"id":"form-input","label":_vm.$t('role'),"description":"Which permissions do you want to give this user?"}},[_c('cc-select',{attrs:{"options":_vm.roles,"required":""},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}})],1),_c('p',{staticClass:"m-0 text-tiny text-gray-500"},[_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("form_admin"))+" ")]),_vm._v(": "+_vm._s(_vm.$t("form_admin_text"))+" ")]),_c('p',{staticClass:"m-0 text-tiny text-gray-500"},[_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("form_editor"))+" ")]),_vm._v(": "+_vm._s(_vm.$t("form_editor_text"))+" ")]),_c('p',{staticClass:"m-0 text-tiny text-gray-500"},[_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("form_contributor"))+" ")]),_vm._v(": "+_vm._s(_vm.$t("form_contributor_text"))+" ")]),_c('p',{staticClass:"m-0 text-tiny text-gray-500"},[_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("form_auditor"))+" ")]),_vm._v(": "+_vm._s(_vm.$t("form_auditor_text"))+" ")]),_c('p',{staticClass:"m-0 text-tiny text-gray-500"},[_c('span',{staticClass:"text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t("form_read_only"))+" ")]),_vm._v(": "+_vm._s(_vm.$t("form_read_only_text"))+" ")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }