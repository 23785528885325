<template>
    <div class="position-relative">
        <i
            v-if="prependIcon"
            :class="[prependIconClass, ' cc-select__icon', 'text-600']"
        ></i>
        <b-select
            plain
            v-model="inputValue"
            :options="options"
            :disabled="disabled"
            :readonly="readonly"
            :size="size"
            :placeholder="placeholder"
            :required="required"
            :text-field="textField"
            :value-field="valueField"
            class="cc-select"
            :class="[
                appendIcon ? 'cc-select--icon' : '',
                clearable ? 'cc-select--clearable' : '',
                error ? 'cc-select--error' : '',
                customClasses,
            ]"
        >
            <template #first v-if="emptyOption">
                <b-form-select-option :value="null" disabled>{{
                    emptyText
                }}</b-form-select-option>
            </template>
        </b-select>
        <i
            v-if="clearable"
            class="fas fa-times cc-select__clear"
            @click="handleInput(null)"
        ></i>
        <i class="fas fa-chevron-down cc-select__chevron"></i>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number, Object],
            required: false,
        },
        options: {
            type: Array,
            required: true,
        },
        size: {
            type: String,
            required: false,
            default: "md",
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false,
        },
        prependIcon: {
            type: String,
            required: false,
            default: null,
        },
        appendIcon: {
            type: Boolean,
            required: false,
            default: null,
        },
        customClasses: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false,
        },
        error: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: "",
        },
        emptyOption: {
            type: Boolean,
            required: false,
            default: false,
        },
        emptyText: {
            type: String,
            required: false,
            default: "Please select an option",
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        textField: {
            type: String,
            required: false,
            default: "text",
        },
        valueField: {
            type: String,
            required: false,
            default: "value",
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        prependIconClass() {
            return this.prependIcon ? this.prependIcon : "fas fa-tag";
        },
    },
};
</script>
