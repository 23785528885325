<template>
    <b-card class="cc-content cc-content-card" v-if="$can('list', 'invite')">
        <b-row>
            <b-col>
                <h1>{{ $t("team_invites_header") }}</h1>
            </b-col>
            <b-col class="text-right">
                <b-button
                    variant="primary"
                    @click="showInviteForm = true"
                    v-if="$can('create', 'invite')"
                    >{{ $t("team_invites_button_invite") }}
                </b-button>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <b-form-checkbox
                    v-model="showActive"
                    name="check-button"
                    switch
                >
                    <span class="text-mini d-flex align-items-center">
                        {{ $t("team_invites_checkbox") }}
                    </span>
                </b-form-checkbox>
            </b-col>
        </b-row>

        <div class="mt-6">
            <b-table
                striped
                hover
                :fields="invitesFields"
                :items="invites"
                responsive
            >
                <template #cell(name)="data">
                    {{ `${data.item.first_name} ${data.item.last_name}` }}
                </template>
                <template #cell(expires_at)="data">
                    <span
                        :class="
                            expiredDate(data.item.expires_at)
                                ? 'text-warning'
                                : ''
                        "
                        >{{
                            new Date(data.item.expires_at).toLocaleString()
                        }}</span
                    >
                </template>
                <template #cell(site_access)="data">
                    <div>
                        <div
                            v-if="
                                createSiteAccessArray(data.item.role_info)
                                    .length > 0
                            "
                        >
                            <b-badge
                                class="badge--blue badge--noclick"
                                :class="{
                                    'badge--red': createSiteAccessArray(
                                        data.item.role_info
                                    )[0].deleted,
                                    'badge--blue': !createSiteAccessArray(
                                        data.item.role_info
                                    )[0].deleted,
                                }"
                            >
                                {{
                                    createSiteAccessArray(
                                        data.item.role_info
                                    )[0].name
                                }}
                            </b-badge>
                            <p
                                v-if="
                                    createSiteAccessArray(data.item.role_info)
                                        .length > 1
                                "
                                class="text-gray-500 light text-small ml-1 mb-0"
                            >
                                {{
                                    $t("team_function_text", {
                                        length: createSiteAccessArray(
                                            data.item.role_info
                                        ).length,
                                    })
                                }}
                            </p>
                        </div>

                        <b-badge class="badge--blue badge--noclick" v-else>
                            {{ $t("all_sites") }}
                        </b-badge>
                    </div>
                </template>
                <template #cell(business_access)="data">
                    <div>
                        <div
                            v-if="
                                createBusinessAccessArray(data.item.role_info)
                                    .length > 0
                            "
                        >
                            <b-badge class="badge--blue badge--noclick">
                                {{
                                    createBusinessAccessArray(
                                        data.item.role_info
                                    )[0].name
                                }}
                            </b-badge>
                            <p
                                v-if="
                                    createBusinessAccessArray(
                                        data.item.role_info
                                    ).length > 1
                                "
                                class="text-gray-500 light text-small ml-1 mb-0"
                            >
                                {{ $t("and") }}
                                {{
                                    createBusinessAccessArray(
                                        data.item.role_info
                                    ).length - 1
                                }}
                                {{ $t("more") }}
                            </p>
                        </div>

                        <b-badge class="badge--blue badge--noclick" v-else>
                            {{ $t("all_business_units") }}
                        </b-badge>
                    </div>
                </template>
                <template #cell(tag_access)="data">
                    <div>
                        <div
                            v-if="
                                createTagsAccessArray(data.item.role_info)
                                    .length > 1
                            "
                        >
                            <b-badge class="badge--blue badge--noclick">
                                {{
                                    createTagsAccessArray(
                                        data.item.role_info
                                    )[0].title
                                }}
                            </b-badge>
                            <p
                                v-if="
                                    createTagsAccessArray(data.item.role_info)
                                        .length > 1
                                "
                                class="text-gray-500 light text-small ml-1 mb-0"
                            >
                                {{ $t("and") }}
                                {{
                                    createTagsAccessArray(data.item.role_info)
                                        .length - 1
                                }}
                                {{ $t("more") }}
                            </p>
                        </div>

                        <b-badge class="badge--blue badge--noclick" v-else>{{
                            $t("all_tags")
                        }}</b-badge>
                    </div>
                </template>
                <template
                    #cell(role)="data"
                    v-if="$can('update', 'company_user')"
                >
                    <div
                        class="d-flex justify-content-start align-items-center"
                        style="text-transform: capitalize"
                    >
                        {{ data.item.role }}
                    </div>
                </template>
                <template #cell(actions)="data">
                    <div>
                        <b-button
                            :id="'edit_button' + data.item.id"
                            size="sm"
                            variant="outline-primary"
                            @click="handleEditInvite(data.item)"
                            v-if="
                                $can('update', 'invite') &&
                                !expiredDate(data.item.expires_at)
                            "
                        >
                            <i class="fas fa-pencil"></i>
                        </b-button>
                        <b-tooltip
                            :target="'edit_button' + data.item.id"
                            triggers="hover"
                        >
                            {{ $t("edit") }}
                        </b-tooltip>
                        <b-button
                            :id="'send_button' + data.item.id"
                            size="sm"
                            variant="secondary"
                            @click="handleResendEmail(data.item)"
                            v-if="$can('create', 'invite')"
                        >
                            <i class="fas fa-envelope"></i>
                        </b-button>
                        <b-tooltip
                            :target="'send_button' + data.item.id"
                            triggers="hover"
                        >
                            {{ $t("send") }}
                        </b-tooltip>
                        <b-button
                            :id="'delete_button' + data.item.id"
                            size="sm"
                            variant="outline-danger"
                            @click="handleRevokeInvite(data.item)"
                            v-if="
                                $can('update', 'invite') &&
                                !expiredDate(data.item.expires_at)
                            "
                        >
                            <i class="fas fa-close"></i>
                        </b-button>
                        <b-tooltip
                            :target="'delete_button' + data.item.id"
                            triggers="hover"
                        >
                            {{ $t("delete") }}
                        </b-tooltip>
                    </div>
                </template>
            </b-table>
        </div>

        <invite-form
            :showModal="showInviteForm"
            :editInvite="editInvite"
            @close="handleCloseInvite"
            @saved="handleCloseInvite"
        ></invite-form>
    </b-card>
</template>

<script>
import inviteApi from "@/api/invite";
import swal from "sweetalert2";
import InviteForm from "./InviteForm.vue";

export default {
    name: "CCInvites",
    components: {
        InviteForm,
    },
    data() {
        return {
            invites: [],
            invitesFields: [
                { key: "name", label: this.$t("label_name") },
                { key: "email", label: this.$t("email") },
                { key: "expires_at", label: this.$t("label_expires") },
                { key: "role", label: this.$t("role") },
                { key: "site_access", label: this.$t("label_site_access") },
                {
                    key: "business_access",
                    label: this.$t("label_business_unit_access"),
                },
                { key: "tag_access", label: this.$t("label_tag_access") },
                { key: "actions", label: this.$t("label_actions") },
            ],
            showInviteForm: false,
            editInvite: {},
            showActive: true,
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.getOpenInvites();
        },
        async getOpenInvites() {
            try {
                const response = await inviteApi.getOpenInvites(
                    !this.showActive
                );
                this.invites = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        handleEditInvite(item) {
            this.editInvite = item;
            this.showInviteForm = true;
        },
        handleCloseInvite() {
            this.editInvite = {};
            this.showInviteForm = false;
            this.getOpenInvites();
        },
        async handleResendEmail(item) {
            const result = await swal.fire({
                title: this.$t("team_invites_handleResendEmail_title"),
                text: this.$t("team_invites_handleResendEmail_text"),
                icon: "question",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.isConfirmed) {
                try {
                    await inviteApi.createInvite(item);
                    this.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async handleRevokeInvite(item) {
            const result = await swal.fire({
                title: this.$t("team_invites_handleRevokeInvite_title"),
                text: this.$t("team_invites_handleRevokeInvite_text"),
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
            });

            if (result.isConfirmed) {
                try {
                    await inviteApi.revokeInvite(item.invite_id);
                    this.init();
                } catch (error) {
                    console.log(error);
                }
            }
        },
        createSiteAccessArray(role_info) {
            if (role_info === null) return [];
            if (!role_info.sites) return [];

            const sites = role_info.sites.map((x) => {
                return (
                    this.$store.getters.getSite(x) ||
                    this.$store.getters.getDeletedSite(x) || {
                        name: `Unknown (${x})`,
                    }
                );
            });
            return sites;
        },
        createBusinessAccessArray(role_info) {
            if (role_info === null) return [];
            if (!role_info.business_units) return [];

            const businesses = role_info.business_units.map((x) => {
                return this.$store.getters.getBusinessUnit(x);
            });
            return businesses;
        },
        createTagsAccessArray(role_info) {
            if (role_info === null) return [];
            if (!role_info.tag_ids) return [];

            const tags = role_info.tag_ids.map((x) => {
                return this.$store.getters.getTag(x);
            });
            return tags;
        },
        expiredDate(date) {
            const expires = new Date(date).getTime();
            return expires < Date.now();
        },
    },
    watch: {
        showActive: {
            handler() {
                this.init();
            },
        },
    },
};
</script>
