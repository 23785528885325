var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.periods && _vm.periods.length > 0)?_c('div',{staticClass:"position-relative h-100"},[_c('div',{staticClass:"menu-button",class:[
            _vm.opened ? 'menu-button--active' : '',
            _vm.highlight ? 'menu-button__highlight' : '',
            _vm.small ? 'menu-button__small' : '',
        ],on:{"click":_vm.toggleMenu}},[(_vm.period.locked === 0)?_c('i',{staticClass:"fa-solid fa-calendar mr-2"}):_c('i',{staticClass:"fas fa-lock mr-2"}),_vm._v(" "+_vm._s(_vm.period.name)+" ")]),_c('div',{staticClass:"menu-select__wrapper",class:_vm.opened ? 'menu-select__wrapper--visible' : '',style:(_vm.shiftLeft ? 'right: -40px;' : '')},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"menu-items__wrapper",class:_vm.opened ? 'menu-items__wrapper--visible' : '',attrs:{"id":"period-select"}},_vm._l((_vm.periods),function(period){return _c('div',{key:period.value,staticClass:"menu-select__item",class:_vm.currentPeriod === period
                            ? 'menu-select__item--selected'
                            : '',on:{"click":function($event){return _vm.selectPeriod(period.id)}}},[_c('span',[(period.locked === 0)?_c('i',{staticClass:"fas fa-calendar-alt mr-3"}):_c('i',{staticClass:"fas fa-lock mr-3"}),_vm._v(" "+_vm._s(period.name)+" "),(_vm.currentPeriod === period.value)?_c('i',{staticClass:"fa-solid fa-circle menu-select__indicator"}):_vm._e()])])}),0)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }