<template>
    <div class="position-relative h-100">
        <!-- FILTER BUTTON -->
        <div
            class="menu-button"
            :class="opened ? 'menu-button--active' : ''"
            @click="toggleMenu"
        >
            <i class="fa-solid fa-filter mr-2"></i>
            {{ $t("filter") }}
            <b-badge class="badge--blue ml-1" v-if="badgeText > 0">{{
                badgeText
            }}</b-badge>
        </div>

        <!-- FILTER MENU -->
        <div
            class="menu-select__wrapper"
            :class="opened ? 'menu-select__wrapper--visible' : ''"
            :style="`transform: translateX(-${shiftLeft}px);`"
        >
            <!-- MAIN FILTER ITEMS -->
            <div class="d-flex flex-column">
                <div
                    class="menu-items__wrapper"
                    id="filter-select"
                    :class="opened ? 'menu-items__wrapper--visible' : ''"
                >
                    <!-- FILTER OPTIONS -->
                    <search-filters
                        class="menu-select__item--no-hover"
                        v-show="!hideFilters.includes('search')"
                        :opened="opened"
                    />
                    <div
                        v-for="filter in availableFilters"
                        :key="filter"
                        class="menu-select__item"
                        @click="selectFilter(filter)"
                        :class="
                            selected === filter
                                ? 'menu-select__item--selected'
                                : ''
                        "
                    >
                        <span>
                            <i
                                class="fas mr-3"
                                :class="generateFilterIcon(filter)"
                            ></i
                            >{{ generateFilterString(filter) }}
                            <i
                                class="fa-solid fa-circle menu-select__indicator"
                                v-if="
                                    filters[filter] !== null &&
                                    filters[filter].length > 0
                                "
                            ></i>
                        </span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </div>

                    <!-- SIDEBARS -->
                    <div
                        class="menu-select__sidebar"
                        id="filter-sidebar"
                        :class="
                            selected !== ''
                                ? 'menu-select__sidebar--visible'
                                : ''
                        "
                        :style="`top: ${menuPosition}px;`"
                    >
                        <SiteFilters v-show="selected === 'sites'" />
                        <BuFilters v-show="selected === 'businessUnits'" />
                        <ScopeFilters v-show="selected === 'scopes'" />
                        <CategoryFilters
                            v-show="selected === 'category'"
                            @selected="toggleMenu"
                        />
                        <TagFilters v-show="selected === 'tags'" />
                        <ValidatedFilters
                            v-show="selected === 'validation'"
                            @selected="toggleMenu"
                        />
                    </div>

                    <!-- CLEAR BUTTON -->
                    <div class="menu-select__clear" @click="clearFilters">
                        <i class="fas fa-xmark mr-3"></i>
                        {{ $t("filters_clear") }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BuFilters from "./BuFilters.vue";
import CategoryFilters from "./CategoryFilters.vue";
import ScopeFilters from "./ScopeFilters.vue";
import SearchFilters from "./SearchFilters.vue";
import SiteFilters from "./SiteFilters.vue";
import TagFilters from "./TagFilters.vue";
import ValidatedFilters from "./ValidatedFilters.vue";

import { mapState } from "vuex";

import * as types from "@/store/types";

export default {
    name: "CCFilters",
    components: {
        SiteFilters,
        BuFilters,
        ScopeFilters,
        CategoryFilters,
        TagFilters,
        ValidatedFilters,
        SearchFilters,
    },
    props: {
        hideFilters: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            possibleFilters: [
                "sites",
                "businessUnits",
                "scopes",
                "category",
                "tags",
                "validation",
            ],
            selected: "",
            opened: false,
            shiftLeft: 0,
            menuPosition: 0,
        };
    },
    computed: {
        ...mapState({
            filters: (state) => state.filters,
        }),
        badgeText() {
            const activeFilters = Object.entries(this.filters).filter(
                (x) =>
                    x[1] !== null &&
                    x[1].length !== 0 &&
                    this.availableFilters.includes(x[0])
            );
            return activeFilters.length;
        },
        availableFilters() {
            return this.possibleFilters.filter((x) => {
                return !this.hideFilters.includes(x);
            });
        },
    },
    methods: {
        toggleMenu() {
            this.opened = !this.opened;
            if (this.opened === false) {
                this.selected = "";
                this.shiftLeft = 0;
            }
            if (this.opened === true) {
                this.checkPosition();
                this.$emit("opened", "filters");
            }
        },
        closeMenu() {
            this.opened = false;
            this.selected = "";
            this.shiftLeft = 0;
            this.menuPosition = 0;
        },
        selectFilter(filter) {
            this.selected = filter;
            this.positionMenu();
        },
        clearFilters() {
            this.$store.dispatch(types.RESET_FILTERS);
            this.toggleMenu();
        },
        generateFilterString(filter) {
            switch (filter) {
                case "sites":
                    return this.$t("filters_by_site");
                case "businessUnits":
                    return this.$t("filters_by_bu");
                case "scopes":
                    return this.$t("filters_by_scope");
                case "category":
                    return this.$t("filters_by_category");
                case "tags":
                    return this.$t("filters_by_tag");
                case "validation":
                    return this.$t("filters_by_validation");
            }
        },
        generateFilterIcon(filter) {
            switch (filter) {
                case "sites":
                    return "fa-map-marker-alt";
                case "businessUnits":
                    return "fa-building";
                case "scopes":
                    return "fa-expand";
                case "category":
                    return "fa-list-radio";
                case "tags":
                    return "fa-tag";
                case "validation":
                    return "fa-badge-check";
            }
        },
        checkPosition() {
            const options = {
                root: document.querySelector("#app"),
                rootMargin: "0px",
                threshold: 1.0,
            };
            const target = document.getElementById("filter-select");
            const sidebar = document.getElementById("filter-sidebar");

            const observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) return;

                    if (this.opened === false) {
                        this.shiftLeft = 0;
                        return;
                    }

                    if (!entry.isIntersecting) {
                        // Consider the starting point
                        const leftRight =
                            entry.intersectionRect.right -
                            entry.intersectionRect.left;

                        // Correct for the starting point but add 30 for padding
                        this.shiftLeft =
                            entry.target.scrollWidth - leftRight + 30;
                    }
                });
            }, options);

            observer.observe(target);
            observer.observe(sidebar);
        },
        positionMenu() {
            this.$nextTick(() => {
                const menuItem = document.querySelector(
                    ".menu-select__item--selected"
                );
                this.menuPosition = menuItem.offsetTop;
            });
        },
    },
};
</script>
